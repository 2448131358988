import React from 'react';

const CountryFlag = (props: any) => {
  const { iso, nicename } = props;
  const size = '4x3';
  return (
    <img
      src={`/images/flags/${size}/${iso.toLowerCase()}.svg`}
      alt={nicename}
      height="25"
    />
  );
};

export default CountryFlag;
