/**
 * Asynchronously loads the component for HomePage
 */

import { lazyLoad } from 'utils/loadable';

export const LegalVASPDisclaimerPage = lazyLoad(
  () => import('./DisclaimerVASP'),
  module => module.LegalVASPDisclaimerPage,
);

export const LegalTermsPage = lazyLoad(
  () => import('./Terms'),
  module => module.LegalTermsPage,
);

export const LegalPrivacyPage = lazyLoad(
  () => import('./Privacy'),
  module => module.LegalPrivacyPage,
);
