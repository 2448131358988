/**
 * Asynchronously loads the component for HomePage
 */

import { lazyLoad } from 'utils/loadable';

export const AuthPage = lazyLoad(
  () => import('./index'),
  module => module.AuthPage,
);

export const LoginPage = lazyLoad(
  () => import('./Login'),
  module => module.LoginPage,
);

export const LogoutPage = lazyLoad(
  () => import('./Logout'),
  module => module.LogoutPage,
);

export const ForgotPasswordPage = lazyLoad(
  () => import('./ForgotPassword'),
  module => module.ForgotPasswordPage,
);

export const ResetPasswordPage = lazyLoad(
  () => import('./ResetPassword'),
  module => module.ResetPasswordPage,
);

export const VerifyLoginPage = lazyLoad(
  () => import('./VerifyLogin'),
  module => module.VerifyLoginPage,
);

export const VerifyMobilePage = lazyLoad(
  () => import('./VerifyMobile'),
  module => module.VerifyMobilePage,
);

export const RegisterPage = lazyLoad(
  () => import('./Register'),
  module => module.RegisterPage,
);

export const ActivateAccountPage = lazyLoad(
  () => import('./ActivateAccount'),
  module => module.ActivateAccountPage,
);
