var environment = 'remote';
if (window.location) {
  var arr = window.location.hostname.split('.');
  if (arr[0] !== 'localhost') {
    environment = 'remote';
  }

  if (arr[0] === 'dao') {
    environment = 'prod';
  }

  if (arr[0] === 'qa') {
    environment = 'qa';
  }
}

if (environment === 'remote') {
  module.exports = require('./remote');
}

if (environment === 'prod') {
  module.exports = require('./prod');
}

if (environment === 'qa') {
  module.exports = require('./qa');
}
