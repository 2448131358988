import React from 'react';

const Default = () => (
  <div className="loader-wrapper">
    <div className="loader-container">
      <div className="loader" />
    </div>
  </div>
);

const Loader = (props: any) => {
  const { type } = props;

  switch (type) {
    case 'card':
      return (
        <div className="loader-wrapper card-loader">
          <div className="loader-container">
            <div className="loader" />
          </div>
        </div>
      );
      break;

    default:
      return <Default />;
  }
};

export default Loader;
