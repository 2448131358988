import React from 'react';
import { Col, Row, Modal, ModalBody } from 'reactstrap';
import NumberFormat from '../common/format-number';
import moment from 'moment';

const Status = (props: any) => {
  const { status } = props;

  if (status.toLowerCase().includes('complete')) {
    return (
      <>
        <div className="verify">
          <div className="verified">
            <span className="me-2">
              <i className="la la-check" />
            </span>
            <a>Verified</a>
          </div>
        </div>
      </>
    );
  }

  if (status.toLowerCase().includes('reject')) {
    return (
      <div className="not-verify">
        <div className="not-verified">
          <span className="me-2">
            <i className="la la-times" />
          </span>
          <small>Rejected</small>
        </div>
      </div>
    );
  }
  return (
    <div className="not-verify">
      <div className="not-verified">
        <span className="me-2 bg-warning">
          <i className="fa fa-hourglass" />
        </span>
        <small>Under Review</small>
      </div>
    </div>
  );
};

const KycDetail = (props: any) => {
  const { show, setShow, data, toggleShowUpgade, limits } = props;
  console.log(props);
  const { level, status, created, reject_reason, verification_date } = data;
  const levelLimits = limits[level];
  console.log(levelLimits);

  const toggle = () => setShow(!show);

  return (
    <div>
      <Modal isOpen={show} toggle={toggle} size="md" backdrop="static">
        <ModalBody>
          <Row>
            <Col xs={12} className="text-center">
              <h2>
                <strong>KYC|</strong>LEVEL {level}&nbsp;&nbsp;&nbsp;&nbsp;
              </h2>
            </Col>
          </Row>
          <hr className="mt-2 mb-2" />
          <Row>
            <Col xs={5} className="text-right">
              Date Applied
            </Col>
            <Col xs={7}>
              {moment(created).format('DD MMM YYYY')},{' '}
              {moment(created).format('HH:mm')}
            </Col>
          </Row>
          <hr className="mt-2 mb-2" />
          <Row>
            <Col xs={5} className="text-right">
              Status
            </Col>
            <Col xs={7}>
              <div className="phone_verified">
                <Status {...data} />
              </div>
            </Col>
          </Row>
          <hr className="mt-2 mb-2" />
          {status.toLowerCase().includes('reject') && reject_reason && (
            <>
              <Row>
                <Col xs={5} className="text-right">
                  Rejection Reason
                </Col>
                <Col xs={7}>{reject_reason}</Col>
              </Row>
              <hr className="mt-2 mb-2" />
            </>
          )}
          {status.toLowerCase().includes('complete') && verification_date && (
            <>
              <Row>
                <Col xs={5} className="text-right">
                  Verification date
                </Col>
                <Col xs={7}>
                  {moment(verification_date).format('DD MMM YYYY')}
                </Col>
              </Row>
              <hr className="mt-2 mb-2" />
            </>
          )}
          <Row>
            <Col xs={5} className="text-right">
              Daily Buy Limit
            </Col>
            <Col xs={7}>
              <NumberFormat
                amount={levelLimits.withdrawal}
                decimalScale={2}
                currencyCode="NAD"
              />
            </Col>
          </Row>
          <hr className="mt-2 mb-2" />
          <Row>
            <Col xs={5} className="text-right">
              Daily Sell Limit
            </Col>
            <Col xs={7}>
              <NumberFormat
                amount={levelLimits.deposit}
                decimalScale={2}
                currencyCode="NAD"
              />
            </Col>
          </Row>
          <hr className="mt-2 mb-2" />
          <Row>
            <Col xs={12} className="text-center mt-1">
              {status.toLowerCase().includes('reject') && (
                <button
                  type="button"
                  className="btn btn-primary bg-gradient-dark me-4"
                  onClick={() => {
                    toggle();
                    toggleShowUpgade(level);
                  }}
                >
                  &nbsp;&nbsp;&nbsp;Re-apply&nbsp;&nbsp;&nbsp;
                </button>
              )}
              <a type="button" className="text-primary" onClick={toggle}>
                Close
              </a>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default KycDetail;
