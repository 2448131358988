import React from 'react';
import ActionButtons from './action-buttons';

const StepOne = (props: any) => {
  return (
    <div>
      <h2>Proof of Residence</h2>
      <div className="mx-5 my-5">
        <div className="id_card mx-5 my-5">
          <img src="/images/settings/id.png" className="img-fluid" alt="KYC" />
        </div>
      </div>
      <h4 className="mt-4">
        You are about to upload your proof of residence. Please ensure that:
      </h4>
      <ul className="mb-4">
        <li>
          It displays your name and physical address and is not older than (3)
          three months
        </li>
        <li>
          The document is a utility/municipality bill, rental invoice, phone
          account, clothing account, bank statement, payslip or salary advice,
          taxes account, income tax return.
        </li>
        <li>This is clearly stating your name as shown on your ID/passport.</li>
        <li>This must display residential address and not PO Box</li>
      </ul>
      <ActionButtons {...props} />
    </div>
  );
};

export default StepOne;
