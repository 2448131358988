import axios from 'axios';

export class GeoLocationService {
  static async get() {
    const options: any = {
      mode: 'no-cors',
      method: 'GET',
      url: 'https://geolocation-db.com/json/',
    };
    return await axios(options)
      .then(res => res.data)
      .catch(err => err);
  }
}
