import { Session } from './session.service';
import { osName, osVersion, browserName, isMobile } from 'react-device-detect';
import queryString from 'query-string';
import axios from 'axios';
import config from '../config';

const token = Session.getToken();
const headers = {
  Authorization: `Bearer ${token}`,
  'Content-Type': 'application/json',
};

export class TransactionService {
  /**
   * Base url
   */
  static baseurl = config.services.transaction;
  static adminBaseurl = config.services.admin;

  static async create(data: any) {
    data.device = {
      browser: browserName,
      os_name: osName,
      os_version: osVersion,
      is_mobile: isMobile,
    };
    const options: any = {
      data,
      method: 'POST',
      mode: 'no-cors',
      url: this.baseurl,
      crossdomain: true,
      headers,
    };
    return await axios(options)
      .then(json => json.data)
      .then(res => res)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  static async buddyTransfer(data: any) {
    data.device = {
      browser: browserName,
      os_name: osName,
      os_version: osVersion,
      is_mobile: isMobile,
    };
    const options: any = {
      data,
      method: 'POST',
      mode: 'no-cors',
      url: `${this.baseurl}buddy/transfer`,
      crossdomain: true,
      headers,
    };
    return await axios(options)
      .then(json => json.data)
      .then(res => res)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  static async get(id) {
    const options: any = {
      mode: 'no-cors',
      method: 'GET',
      url: this.baseurl + id,
      crossdomain: true,
      headers,
    };
    return axios(options)
      .then(json => json.data)
      .then(res => res.data)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  static async totals(params: any = null) {
    const query = params ? `?${queryString.stringify(params)}` : '';
    const options: any = {
      mode: 'no-cors',
      method: 'GET',
      url: `${this.baseurl}totals${query}`,
      crossdomain: true,
      headers,
    };
    return axios(options)
      .then(json => json.data)
      .then(res => res.data)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  static async list(params: any = null) {
    const query = params ? `?${queryString.stringify(params)}` : '';
    const options: any = {
      mode: 'no-cors',
      method: 'GET',
      url: this.baseurl + query,
      crossdomain: true,
      headers,
    };
    return axios(options)
      .then(json => json.data)
      .then(res => res.data)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  static async limits(query: any = null) {
    const options: any = {
      mode: 'no-cors',
      method: 'GET',
      url: `${this.baseurl}limits${
        query ? `?${queryString.stringify(query)}` : ''
      }`,
      crossdomain: true,
      headers,
    };
    return axios(options)
      .then(json => json.data)
      .then(res => res.data)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  static async fees(subtype: any) {
    const options: any = {
      mode: 'no-cors',
      method: 'GET',
      url: `${this.baseurl}fees?subtype=${subtype}`,
      crossdomain: true,
      headers,
    };
    return axios(options)
      .then(json => json.data)
      .then(res => res.data)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  static async validateICOOrder(amount: any) {
    const options: any = {
      mode: 'no-cors',
      method: 'GET',
      url: `${this.adminBaseurl}ico/order?token_amount=${amount}`,
      crossdomain: true,
      headers,
    };
    return axios(options)
      .then(json => json.data)
      .then(res => res)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  static async icoStats(type: any = 'daily_token_sales') {
    const options: any = {
      mode: 'no-cors',
      method: 'GET',
      url: `${this.baseurl}ico/${type}/stats`,
      crossdomain: true,
      headers,
    };
    return axios(options)
      .then(json => json.data)
      .then(res => res.data)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }
}
