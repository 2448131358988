import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Dropzone } from '../../media';
import ActionButtons from './action-buttons';

const StepThree = (props: any) => {
  const { error, setError, identityType, setDocumentBack, setDocumentFront } =
    props;
  const [backError, setBackError] = useState<boolean>(false);
  const [frontError, setFrontError] = useState<boolean>(false);

  const handleLastStep = () => {
    props.lastStep();
    props.completeCallback();
  };

  return (
    <div>
      <h2>Identity Verification</h2>
      <h5 className="mt-0 mb-4">
        <strong>Upload photo of you holding your own {identityType}</strong>
      </h5>
      {error && <div className="alert alert-danger">{error}</div>}
      {identityType === 'passport' ? (
        <Row>
          <Col md={12}>
            <Dropzone
              filename="id"
              setFile={(file: any) => {
                setDocumentFront(file);
                setError(null);
              }}
              label="Upload or drop your passport document here"
              onSizeError={(file: any) =>
                setError(
                  'File maximum size (5MB) exceeded. Please upload a smaller file in size.',
                )
              }
            />
          </Col>
        </Row>
      ) : (
        <Row>
          <Col md={6}>
            You holding ID Card Front
            <Dropzone
              filename="id"
              setFile={(file: any) => {
                setDocumentFront(file);
                setFrontError(false);
                if (!backError) {
                  setError(null);
                }
              }}
              label="Upload front here"
              onSizeError={(file: any) => {
                setFrontError(true);
                setError(
                  'File maximum size (5MB) exceeded. Please upload a smaller file in size.',
                );
              }}
            />
          </Col>
          <Col md={6}>
            You holding ID Card Back
            <Dropzone
              filename="id"
              setFile={(file: any) => {
                setDocumentBack(file);
                setBackError(false);
                if (!frontError) {
                  setError(null);
                }
              }}
              label="Upload back here"
              onSizeError={(file: any) => {
                setBackError(true);
                setError(
                  'File maximum size (5MB) exceeded. Please upload a smaller file in size.',
                );
              }}
            />
          </Col>
        </Row>
      )}
      <h4>Ensure that the document meet all the standard below:</h4>
      <ul>
        <li>Size does not exceed 5MB</li>
        <li>Readable, clear and well-lit</li>
        <li>Not reflective, not blurry</li>
        <li>No black and white images, no edited</li>
        <li>You holding your own {identityType}.</li>
        <li>Supported file formats/types: JPG, PNG, PDF, JPEG</li>
      </ul>
      <br />
      <ActionButtons
        {...props}
        lastStep={handleLastStep}
        disabled={error ? true : false}
      />
    </div>
  );
};

export default StepThree;
