import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import ActionButtons from './action-buttons';
import CountryFlag from '../../common/country-flag';

const StepThree = (props: any) => {
  const { handleComplete, countries, error } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [selectedCountry, setSelectedCountry] = useState<any>({});

  async function onSubmit(formData) {
    delete formData.country;
    formData.country_code = selectedCountry.iso;
    handleComplete(formData);
  }

  const handleCountrySelect = (val: any) => {
    setSelectedCountry(countries.filter((item: any) => item.id === val)[0]);
  };

  return (
    <div>
      <h2>Address Information</h2>
      <p className="mt-2 mb-4">
        Please complete your residential address information below:
      </p>
      {error && <div className="alert alert-danger">{error}</div>}
      <form onSubmit={handleSubmit(onSubmit)} className="signin_validate">
        <div className="mb-3">
          <label htmlFor="line_1">Residential address</label>
          <input
            type="text"
            autoCapitalize="off"
            autoComplete="new-password"
            className={`form-control ${errors.line_1 ? 'is-invalid' : ''}`}
            placeholder="Address line 1"
            {...register('line_1', {
              required: true,
            })}
          />
        </div>
        <div className="mb-3">
          <input
            type="text"
            autoCapitalize="off"
            autoComplete="new-password"
            className={`form-control ${errors.line_2 && 'is-invalid'}`}
            placeholder="Address line 2 (optional)"
            {...register('line_2', {
              required: false,
            })}
          />
        </div>
        <div className="mb-3">
          <input
            type="text"
            autoCapitalize="off"
            autoComplete="new-password"
            className={`form-control ${errors.suburb && 'is-invalid'}`}
            placeholder="Suburb"
            {...register('suburb', {
              required: true,
            })}
          />
        </div>
        <div className="mb-3">
          <input
            type="text"
            autoCapitalize="off"
            autoComplete="new-password"
            className={`form-control ${errors.city && 'is-invalid'}`}
            placeholder="City"
            {...register('city', {
              required: true,
            })}
          />
        </div>
        <div className="mb-3">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text">
                {selectedCountry.iso ? (
                  <CountryFlag {...selectedCountry} />
                ) : (
                  <span>...</span>
                )}
              </label>
            </div>
            <select
              defaultValue="0"
              className={`form-control ${errors.country && 'is-invalid'}`}
              {...register('country', {
                required: true,
                minLength: 2,
              })}
              onChange={(e: any) => handleCountrySelect(e.target.value)}
            >
              <option value="">Select country</option>
              {countries.map((item: any) => (
                <option key={item.id} value={item.id}>
                  {item.nicename}
                </option>
              ))}
            </select>
          </div>
        </div>
        <Row>
          <Col xs={7}>
            <div className="mb-3">
              <input
                type="text"
                autoCapitalize="off"
                autoComplete="new-password"
                className={`form-control ${
                  errors.state_province && 'is-invalid'
                }`}
                placeholder="State or province"
                {...register('state_province', {
                  required: true,
                })}
              />
            </div>
          </Col>
          <Col xs={5}>
            <div className="mb-3">
              <input
                type="text"
                autoCapitalize="off"
                autoComplete="new-password"
                className={`form-control ${errors.postal_code && 'is-invalid'}`}
                placeholder="Postal/Zip code"
                {...register('postal_code', {
                  required: true,
                })}
              />
            </div>
          </Col>
        </Row>
        <ActionButtons {...props} />
      </form>
    </div>
  );
};

export default StepThree;
