import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import InputError from '../../components/form/error';
import validationMessages from '../../../static/validation-messages.json';

const errorMessages = validationMessages.bank_account;

const UpdateBankAccountModal = props => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm();
  const {
    data,
    show,
    setShow,
    setPostId,
    setPostData,
    setPageLoading,
    setTransaction,
    setShowOtpVerify,
  } = props;
  const [agree, setAgree] = useState<boolean>(false);
  const [entity, setEntity] = useState<string>('Dafribank');
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(true);

  async function fetchData() {
    setPostId(data.id);
    setEntity(data.bank_name || 'Dafribank');
    setTransaction('bank-account.update');
    setPageLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  const toggle = () => setShow(!show);

  async function onSubmit(data) {
    toggle();
    setPostData(data);
    setShowOtpVerify(true);
  }

  console.log('isDirty: ', isDirty);

  return (
    <div>
      <Modal isOpen={show} toggle={toggle} size="md" backdrop="static">
        {showDisclaimer ? (
          <ModalBody className="text-center">
            <img
              src="/images/icons/warning.png"
              className="icon my-3"
              alt="Warning"
            />
            <p className="mx-3">
              For security purposes, <strong>no withdrawals</strong>&nbsp; will
              be allowed for <strong>24 hours</strong> after changes to any bank
              account details or crypto address details.
            </p>
            <p>
              By proceeding you agree to&nbsp;
              <a
                href="/terms"
                className="text-primary"
                target="_blank"
                rel="noopener noreferrer"
              >
                CBI-X Terms and Conditions
              </a>
            </p>
            <button
              type="button"
              className="btn btn-success w-100 bg-gradient-dark px-4"
              onClick={() => setShowDisclaimer(false)}
            >
              Got it, proceed
            </button>
            <div className="mt-3 mb-1 text-center">
              <a
                href=" "
                className="text-muted"
                onClick={(e: any) => {
                  e.preventDefault();
                  return toggle();
                }}
              >
                Cancel
              </a>
            </div>
          </ModalBody>
        ) : (
          <>
            <ModalHeader toggle={toggle}>Update {entity} Account</ModalHeader>
            <ModalBody className="pt-0">
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="signin_validate"
              >
                <div className="mb-3 mt-3">
                  <label className="me-sm-2 mb-2">Specify</label>
                  <select
                    className={`form-control ${
                      errors.type ? 'is-invalid' : ''
                    }`}
                    defaultValue={entity}
                    onChange={(e: any) => setEntity(e.target.value)}
                  >
                    <option value="Dafribank">Dafribank</option>
                    {/* <option value="Bank">Bank Account</option> */}
                  </select>
                </div>
                <div className="mb-3">
                  <label className="me-sm-2 mb-2">Account name</label>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.name ? 'is-invalid' : ''
                    }`}
                    placeholder="Account holder or name"
                    defaultValue={data.name}
                    {...register('name', {
                      required: true,
                      minLength: 3,
                      validate: value =>
                        validator.isAlpha(value, 'en-US', { ignore: ' ' }),
                    })}
                  />
                  <InputError
                    name="name"
                    errors={errors}
                    messages={errorMessages.name}
                  />
                </div>
                {entity === 'Dafribank' ? (
                  <>
                    <input
                      type="hidden"
                      defaultValue={entity}
                      {...register('bank_name', { required: true })}
                    />
                    <input
                      type="hidden"
                      defaultValue="Current"
                      {...register('type', { required: true })}
                    />
                    <input
                      type="hidden"
                      defaultValue="000000"
                      {...register('branch_code', { required: true })}
                    />
                    <div className="mb-3">
                      <label className="me-sm-2 mb-2">Account number</label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.number ? 'is-invalid' : ''
                        }`}
                        placeholder="Account number"
                        defaultValue={data.number}
                        {...register('number', {
                          required: true,
                          maxLength: 12,
                          validate: value => validator.isNumeric(value),
                        })}
                      />
                      <InputError
                        name="number"
                        errors={errors}
                        messages={errorMessages.number}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="mb-3">
                      <label className="me-sm-2 mb-2">Bank name</label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.bank_name ? 'is-invalid' : ''
                        }`}
                        placeholder="Name of the bank"
                        defaultValue={data.bank_name}
                        {...register('bank_name', {
                          required: true,
                          minLength: 3,
                          validate: value =>
                            validator.isAlpha(value, 'en-US', { ignore: ' ' }),
                        })}
                      />
                      <InputError
                        name="bank_name"
                        errors={errors}
                        messages={errorMessages.bank_name}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="me-sm-2 mb-2">Account number</label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.number ? 'is-invalid' : ''
                        }`}
                        placeholder="Account number"
                        defaultValue={data.number}
                        {...register('number', {
                          required: true,
                          maxLength: 12,
                          validate: value => validator.isNumeric(value),
                        })}
                      />
                      <InputError
                        name="number"
                        errors={errors}
                        messages={errorMessages.number}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="me-sm-2 mb-2">Account type</label>
                      <select
                        defaultValue={data.type}
                        className={`form-control ${
                          errors.type ? 'is-invalid' : ''
                        }`}
                        {...register('type', { required: true })}
                      >
                        <option value="">Select</option>
                        <option value="Cheque">Cheque</option>
                        <option value="Current">Current</option>
                        <option value="Savings">Savings</option>
                        <option value="Transmission">Transmission</option>
                      </select>
                      <InputError
                        name="type"
                        errors={errors}
                        messages={errorMessages.type}
                      />
                    </div>
                    <div className="mb-4">
                      <label className="me-sm-2 mb-2">Branch code</label>
                      <input
                        type="text"
                        placeholder="Branch code"
                        defaultValue={data.branch_code}
                        className={`form-control ${
                          errors.branch_code ? 'is-invalid' : ''
                        }`}
                        maxLength={6}
                        {...register('branch_code', {
                          required: true,
                          minLength: 6,
                          maxLength: 6,
                          validate: value => validator.isNumeric(value),
                        })}
                      />
                      <InputError
                        name="branch_code"
                        errors={errors}
                        messages={errorMessages.branch_code}
                      />
                    </div>
                  </>
                )}
                <div className="mb-3 mb-0">
                  <label className="toggle">
                    <input
                      type="checkbox"
                      className="toggle-checkbox"
                      onChange={(e: any) => setAgree(e.target.checked)}
                    />
                    <div className="toggle-switch" />
                    <span className="toggle-label">
                      By submitting you agree to&nbsp;
                      <a
                        href="/terms"
                        className="text-primary"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        CBI-X Terms and Conditions
                      </a>
                    </span>
                  </label>
                </div>
                <div className="text-center mb-3">
                  <button
                    type="submit"
                    className="btn btn-success bg-gradient-dark w-100"
                    disabled={!agree}
                  >
                    Save Changes
                  </button>
                </div>
              </form>
            </ModalBody>
          </>
        )}
      </Modal>
    </div>
  );
};

export default UpdateBankAccountModal;
