import React from 'react';
import CountryFlag from '../../common/country-flag';

const StepOne = (props: any) => {
  const { countries, setIdentityType, selectedCountry, setSelectedCountry } =
    props;

  const handleCountryChange = (e: any) => {
    const [filtered] = countries.filter(
      (country: any) => country.id === e.target.value,
    );
    setSelectedCountry(filtered);
  };

  return (
    <div style={{ position: 'relative' }}>
      <h2>Identity Verification</h2>
      <br />
      <div className="form-grop mb-3">
        <label>Select your document issuing country/region</label>
        {countries.length === 0 ? (
          <input className="form-control" defaultValue="Loading..." disabled />
        ) : (
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text">
                {selectedCountry.iso ? (
                  <CountryFlag {...selectedCountry} />
                ) : (
                  <span>...</span>
                )}
              </label>
            </div>
            <select
              defaultValue={selectedCountry.id}
              className="form-control"
              onChange={handleCountryChange}
            >
              <option value="">Select</option>
              {countries.map((item: any) => (
                <option key={item.id} value={item.id}>
                  {item.nicename}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
      <div className="form-group mb-3 mb-0 mt-4">
        <label className="mb-0 pb-0">
          Use a valid government-issued document
        </label>
      </div>
      <small>
        Only the following documents listed below will be accepted, all other
        documents will be rejected.
      </small>
      <div className="mt-3">
        <button
          className="btn btn-primary bg-gradient-dark w-100 mb-3"
          onClick={() => {
            props.nextStep();
            setIdentityType('identity document');
          }}
        >
          Identity Document ({selectedCountry.nicename || ''})
        </button>
        <button
          className="btn btn-success bg-gradient-primary text-white w-100 mb-3"
          onClick={() => {
            props.nextStep();
            setIdentityType('passport');
          }}
        >
          Passport
        </button>
      </div>
    </div>
  );
};

export default StepOne;
