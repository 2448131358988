import React from 'react';
import { Col, Row, Button } from 'reactstrap';

const ActionButtons = (props: any) => {
  const handleBack = () => {
    props.previousStep();
  };

  const handleNext = () => {
    if (props.currentStep === 2) {
      /// validate if file(s) uploaded
      if (!props.document) {
        return props.setError(
          <p className="mb-0">
            Please choose proof of residence document file to upload.
          </p>,
        );
      }
    }
    props.nextStep();
  };

  return (
    <div className="kyc-action-buttons">
      <Row>
        {props.currentStep > 1 && (
          <Col>
            <Button
              type="button"
              onClick={handleBack}
              className="btn btn-outline-secondary btn-back"
            >
              <i className="fa fa-angle-left" />
              Back
            </Button>
          </Col>
        )}
        <Col className="text-right">
          {props.currentStep < props.totalSteps && (
            <Button
              onClick={handleNext}
              className="btn btn-success bg-gradient-dark btn-next"
              disabled={props.disabled || false}
            >
              Next
              <i className="fa fa-angle-right" />
            </Button>
          )}
          {props.currentStep === props.totalSteps && (
            <Button
              type="submit"
              className="btn btn-success bg-gradient-dark btn-next"
            >
              Finish
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ActionButtons;
