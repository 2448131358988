import axios from 'axios';
import config from '../config';

const baseurl = config.services.auth;
const headers: any = {
  'Content-Type': 'application/json',
};

export class Session {
  static cookiename = config.auth.cookie.name;
  static alertcookie = `${config.auth.cookie.name}_alert`;

  static start(token) {
    this.set(token);
  }

  static destroy() {
    if (
      sessionStorage.removeItem &&
      typeof sessionStorage.removeItem === 'function'
    ) {
      return sessionStorage.removeItem(this.cookiename);
    }
    return false;
  }

  static set(jwt: any) {
    if (
      sessionStorage.setItem &&
      typeof sessionStorage.setItem === 'function'
    ) {
      sessionStorage.setItem(this.cookiename, jwt);
      return true;
    }
    return false;
  }

  static get() {
    let jwt: any = null;
    let session: any = null;

    if (
      sessionStorage.getItem &&
      typeof sessionStorage.getItem === 'function'
    ) {
      jwt = sessionStorage.getItem(this.cookiename);
    }

    try {
      if (jwt) {
        const base64Url = jwt.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        session = JSON.parse(window.atob(base64));
      }
    } catch (error) {
      console.log('session.get() error: ', error);
    }

    return session;
  }

  static getTokenData(token: any) {
    let session = null;
    try {
      if (token) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        session = JSON.parse(window.atob(base64));
      }
    } catch (error) {
      console.log('session.getTokenData() error: ', error);
    }
    return session;
  }

  static async isValid() {
    let valid: boolean = false;
    const token: any = this.getToken(); // get jwt token

    if (token) {
      headers.Authorization = `Bearer ${token}`;
      const options: any = {
        mode: 'no-cors',
        method: 'GET',
        url: baseurl,
        crossdomain: true,
        headers,
      };
      valid = await axios(options)
        .then(json => json.data)
        .then(res => {
          const { success } = res;
          return success || false;
        })
        .catch(() => {
          return false;
        });
    }

    return valid;
  }

  // return the token from the session storage
  static getToken() {
    let jwt: any = null;

    if (
      sessionStorage.getItem &&
      typeof sessionStorage.getItem === 'function'
    ) {
      jwt = sessionStorage.getItem(this.cookiename);
    }

    return jwt;
  }
}
