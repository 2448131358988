import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { CurrencyService } from '../../services/currency.service';
import { CalculationsService } from '../../services/calculations.service';
import { UserService } from '../../services/user.service';
import { _mask } from '../../../utils/fn';
import Loader from '../common/loader';
import Swal from 'sweetalert2';
import moment from 'moment';

const CryptoDepositModal = props => {
  const {
    show,
    amount,
    setShow,
    profile,
    setPostData,
    currencyCode,
    setTransaction,
    currencyNetwork,
    setShowOtpVerify,
  } = props;
  const [agree, setAgree] = useState<boolean>(false);
  const [currency, setCurrency] = useState<any>(null);
  const [showAddress, setShowAddress] = useState<boolean>(false);
  const [amountInCBI, setAmountInCBI] = useState<any>(0.0);
  const [receiptAmount, setReceiptAmount] = useState<any>(0.0);
  const [selectedAccount, setSelectedAccount] = useState<any>({});
  const [allowSubmit, setAllowSubmit] = useState<boolean>(false);
  const [currencyNetworkDetail, setCurrencyNetworkDetail] = useState<any>();
  const [withdrawalLocked, setWithdrawalLocked] = useState<boolean>(false);
  const [componentLoading, setComponentLoading] = useState<boolean>(true);
  const [noCryptoAccount, setNoCryptoAccount] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<number>(30);
  const [accounts, setAccounts] = useState<any>([]);

  async function fetchData() {
    setTransaction('transaction.withdraw');
    const accounts: any = await UserService.cryptoAccounts();
    const currency: any = await CurrencyService.get(currencyCode);
    const calculatedAmount: any = await CalculationsService.withdraw(
      amount.toString(),
      currencyCode,
    );
    let filtered = [];
    if (accounts.results) {
      filtered = accounts.results.filter(
        (account: any) => account.code === currencyCode,
      );
      setSelectedAccount(filtered[0]);
      setAccounts(filtered || []);
    }
    if (filtered.length === 0) {
      setNoCryptoAccount(true);
    }
    if (
      calculatedAmount &&
      calculatedAmount[currencyCode] &&
      parseFloat(calculatedAmount[currencyCode]) > 0
    ) {
      setAllowSubmit(true);
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'System error! Sorry, please try again!',
        showConfirmButton: false,
        timer: 4000,
      });
      return setTimeout(async function () {
        window.location.href = '/account';
      }, 4000);
    }

    const [curr] = currency.currency_networks.filter(
      (item: any) => item.network_code === currencyNetwork,
    );
    setCurrencyNetworkDetail(curr);
    setReceiptAmount(calculatedAmount[currencyCode] || 0.0);
    setAmountInCBI(calculatedAmount.INPUT_CBI || 0.0);
    setCurrency(currency);

    // check if withdrawal lock is still active
    if (profile.withdrawal_lock_expiry) {
      setWithdrawalLocked(
        moment().isBefore(moment(profile.withdrawal_lock_expiry)),
      );
    }

    setComponentLoading(false);
  }

  async function reCalculatePrice() {
    const calculatedAmount: any = await CalculationsService.withdraw(
      amount.toString(),
      currencyCode,
    );
    setReceiptAmount(calculatedAmount[currencyCode] || 0.0);
    setAmountInCBI(calculatedAmount.INPUT_CBI || 0.0);
  }

  useEffect(() => {
    fetchData();

    /**
     * START: Timer logic implementation
     * -----------------------------------------
     * Specify a 30 seconds timer, so that every
     * 30 seconds new sell price is calculated
     * based on new exchange rates.
     */
    if (currencyCode !== 'CBI') {
      let sec = 30;
      setInterval(() => {
        sec--;
        if (sec === 0) sec = 30;
        setCountdown(sec);
      }, 1000);

      const interval: any = setInterval(() => {
        reCalculatePrice();
      }, 30000);

      return () => clearInterval(interval);
    }

    /**
     * END: Timer logic implementation
     * -----------------------------------------
     */
  }, []);

  const toggle = () => setShow(!show);

  const toggleAddress = () => setShowAddress(!showAddress);

  const handleCryptoChange = async (e: any) => {
    const [filtered] = accounts.filter(
      (account: any) => account.id === e.target.value,
    );
    if (filtered) {
      setSelectedAccount(filtered);
      setShowAddress(false);
    }
  };

  const handleProcessWithdrawal = async () => {
    toggle();
    setPostData({
      type: 'crypto',
      tx_type: 'debit',
      subtype: 'withdrawal',
      amount: amount.toString(),
      currency_code: currencyCode,
      account_id: selectedAccount.id,
      currency_network: currencyNetwork,
    });
    setShowOtpVerify(true);
  };

  // account lock for withdrawals
  if (withdrawalLocked) {
    return (
      <Modal isOpen={show} toggle={toggle} size="md" backdrop="static">
        <ModalBody className="text-center">
          <img
            src="/images/icons/danger.png"
            className="icon x2 mb-4 mt-5 opacity-6"
            alt="Warning"
          />
          <p className="mx-2 mb-4" style={{ color: '#808080' }}>
            For security purposes, <strong>no withdrawals</strong>&nbsp; allowed
            for <strong>24 hours</strong> after changes to any bank account
            details or crypto address details.
            <br />
            You will only be able to withdraw from&nbsp;
            <strong>
              {moment(profile.withdrawal_lock_expiry).format('DD-MMM-YYYY')}
            </strong>
            &nbsp;at&nbsp;
            <strong>
              {moment(profile.withdrawal_lock_expiry).format('HH:mm')}
            </strong>
          </p>
          <div className="mt-3 mb-2 text-center">
            <a
              href=" "
              className="btn btn-success w-100 bg-gradient-dark"
              onClick={(e: any) => {
                e.preventDefault();
                return toggle();
              }}
            >
              Close
            </a>
          </div>
        </ModalBody>
      </Modal>
    );
  }

  return (
    <div>
      <Modal isOpen={show} toggle={toggle} size="md" backdrop="static">
        {componentLoading && <Loader type="card" />}
        {noCryptoAccount ? (
          <ModalBody className="text-center">
            <img
              src="/images/no-history.png"
              className="icon x2 mb-4 mt-5 opacity-5"
              alt="Warning"
            />
            <p className="mx-2 mb-4" style={{ color: '#808080' }}>
              You have no linked account/address for {currencyCode} sell. Click
              the button below or navigate to settings screen to add bank
              account.
            </p>
            <Link
              to="/account/settings?section=linked-accounts"
              className="btn btn-primary w-100 bg-gradient-primary"
            >
              Go to settings
            </Link>
            <div className="mt-3 mb-1 text-center">
              <a
                href=" "
                className="text-muted"
                style={{ color: '#808080' }}
                onClick={(e: any) => {
                  e.preventDefault();
                  return toggle();
                }}
              >
                Cancel
              </a>
            </div>
          </ModalBody>
        ) : (
          <>
            <ModalHeader toggle={toggle}>
              {currencyCode !== 'CBI' && <span>({countdown}) </span>}
              {agree ? 'Make your sale' : 'Before you sell, please note:'}
            </ModalHeader>
            <ModalBody className="pt-0">
              {agree ? (
                <>
                  <div className="buyer-seller">
                    {currencyCode === 'CBI' ? (
                      <>
                        <small className="mt-3 mb-4 d-block">
                          Choose your an account from a list of your verified
                          wallets below, or you can navigate to the settings
                          screen to add a new account.
                        </small>
                        <div className="mb-0">
                          <label className="me-sm-2 mb-1">
                            <strong>Choose account you want to sell to</strong>
                          </label>
                          {selectedAccount && selectedAccount.id && (
                            <select
                              className="form-control"
                              defaultValue={selectedAccount.id}
                              onChange={handleCryptoChange}
                            >
                              {accounts.map((account: any) => (
                                <option key={account.id} value={account.id}>
                                  {account.name} -{' '}
                                  {currencyCode === 'CBI'
                                    ? account.address
                                    : _mask(account.address)}
                                </option>
                              ))}
                            </select>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <small className="mt-3 mb-2 d-block">
                          Choose your an address from a list of your verified
                          crypto addresses below, or you can navigate to the
                          settings screen to add a new address/account.
                        </small>
                        <div className="mb-3">
                          <label className="me-sm-2">
                            Choose crypto address you want to sell to
                          </label>
                          {selectedAccount && selectedAccount.id && (
                            <select
                              className="form-control"
                              defaultValue={selectedAccount.id}
                              onChange={handleCryptoChange}
                            >
                              {accounts.map((account: any) => (
                                <option key={account.id} value={account.id}>
                                  {account.name} -{' '}
                                  {currencyCode === 'CBI'
                                    ? account.address
                                    : _mask(account.address)}
                                </option>
                              ))}
                            </select>
                          )}
                        </div>
                      </>
                    )}
                    <div className="table-responsive">
                      <table className="table mb-0">
                        <tbody>
                          <tr>
                            <td className="deposit-details">
                              <div className="row">
                                <small>CRYPTO CURRENCY</small>
                                <label>
                                  {currency && (
                                    <>
                                      {currency.code} - {currency.description} (
                                      {currency.symbol})
                                    </>
                                  )}
                                </label>
                              </div>
                              {currencyNetwork && currency.code === 'USDT' && (
                                <div className="row">
                                  <small>CHOSEN {currency.code} NETWORK</small>
                                  <label>
                                    {currencyNetworkDetail.network.description}
                                    &nbsp; ({currencyNetworkDetail.network.code}
                                    )
                                  </label>
                                </div>
                              )}
                              {currencyCode === 'CBI' ? (
                                <div className="row">
                                  <small>CBI GLOBAL USER CODE</small>
                                  <label>
                                    <span className="me-2">
                                      {selectedAccount.address}
                                    </span>
                                  </label>
                                </div>
                              ) : (
                                <div className="row">
                                  <small>ADDRESS</small>
                                  <label>
                                    <span className="me-2">
                                      {showAddress
                                        ? selectedAccount.address
                                        : _mask(selectedAccount.address)}
                                    </span>
                                    <span
                                      role="button"
                                      className="pointer-on-hover"
                                      onClick={toggleAddress}
                                    >
                                      <i
                                        className={`fa ${
                                          showAddress
                                            ? 'fa-eye-slash'
                                            : 'fa-eye'
                                        }`}
                                      />
                                    </span>
                                  </label>
                                </div>
                              )}
                              <div className="row">
                                <small>AMOUNT TO BE DEDUCTED</small>
                                <label>{amountInCBI} CBI</label>
                              </div>
                              <div className="row">
                                <small>ESTIMATED AMOUNT YOU WILL RECEIVE</small>
                                <label>
                                  {receiptAmount} {currency.code}
                                </label>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <ul className="unordered-list mb-3">
                    <li>
                      <small>
                        The quoted amount may change at any given time due to
                        the fluctuating exchange rates of {currencyCode}.
                      </small>
                    </li>
                    <li>
                      <small>
                        By continuing with this transaction you agree to&nbsp;
                        <a
                          href="/terms"
                          className="text-primary"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          CBI-X Terms and Conditions
                        </a>
                      </small>
                    </li>
                  </ul>
                  <div className="d-block text-center mb-2 mt-2">
                    <button
                      type="button"
                      className="btn btn-primary bg-gradient-dark px-5"
                      disabled={!allowSubmit}
                      onClick={handleProcessWithdrawal}
                    >
                      Confirm &amp; Submit
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <ul className="unordered-list mb-3 mt-3">
                    <li>
                      <small>
                        The quoted amount may change at any given time due to
                        the fluctuating exchange rates of {currencyCode}.
                      </small>
                    </li>
                    <li>
                      <small>
                        By selling you agree to&nbsp;
                        <a
                          href="/terms"
                          className="text-primary"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          CBI-X Terms and Conditions
                        </a>
                      </small>
                    </li>
                  </ul>
                  <div className="mb-3 mb-0">
                    <label className="toggle">
                      <input
                        type="checkbox"
                        className="toggle-checkbox"
                        onChange={(e: any) => setAgree(e.target.checked)}
                      />
                      <div className="toggle-switch" />
                      <span className="toggle-label">
                        I understand and accept
                      </span>
                    </label>
                  </div>
                </>
              )}
            </ModalBody>
          </>
        )}
      </Modal>
    </div>
  );
};

export default CryptoDepositModal;
