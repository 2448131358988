import axios from 'axios';
import config from '../config';
import { Session } from './session.service';

const token = Session.getToken();
const headers = {
  Authorization: `Bearer ${token}`,
  'Content-Type': 'application/json',
};

export class ExchangeService {
  /**
   * Base url
   */
  static baseurl = config.services.generic;

  /**
   * Get exchange rates
   *
   * @param {string} base  base asset
   * @param {string} quote quote asset
   * @returns {number}
   */
  static async rates(base: string, quote: string) {
    const options: any = {
      mode: 'no-cors',
      method: 'GET',
      url: `${this.baseurl}exchange/rates/${base}/${quote}`,
      crossdomain: true,
      headers,
    };

    return axios(options)
      .then(json => json.data)
      .then(res => res.data)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }
}
