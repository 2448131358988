import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { CurrencyService } from '../../services/currency.service';
import { ExchangeService } from '../../services/exchange.service';
import { TransactionService } from '../../services/transaction.service';
import { _mask } from '../../../utils/fn';
import CurrencyFormat from 'react-currency-format';
import Loader from '../common/loader';
import Swal from 'sweetalert2';

const PostDisclaimerForm = (props: any) => {
  const {
    agree,
    usdAmount,
    tokens,
    currency,
    cbiAmount,
    allowSubmit,
    setAgree,
  } = props;
  return (
    <>
      <div className="buyer-seller">
        <small className="mt-3 mb-1 d-block">
          Please verify information below, after confirming you can submit your
          purchase request.
        </small>
        <div className="table-responsive">
          <table className="table mb-0">
            <tbody>
              <tr>
                <td className="deposit-details">
                  <div className="row mt-1">
                    <small>ESTIMATED AMOUNT TO BE DEDUCTED</small>
                    <label>
                      <CurrencyFormat
                        thousandSeparator=" "
                        displayType="text"
                        value={cbiAmount}
                        fixedDecimalScale
                        decimalScale={4}
                        renderText={(value: any) => <span>{value} CBI</span>}
                      />
                      <CurrencyFormat
                        thousandSeparator=" "
                        displayType="text"
                        value={usdAmount}
                        fixedDecimalScale
                        decimalScale={2}
                        renderText={(value: any) => (
                          <span className="sub-amt"> ( {value} USD )</span>
                        )}
                      />
                    </label>
                  </div>
                  <div className="row">
                    <small>ESTIMATED TOKENS YOU WILL RECEIVE</small>
                    <label>
                      {tokens} {currency.code}
                    </label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <ul className="unordered-list mb-3">
        <li>
          <small>Amounts calculated based current token price.</small>
        </li>
        <li>
          <small>
            The above estimated amount will be deducted from your CBI-X wallet.
          </small>
        </li>
        <li>
          <small>
            The estimated amounts may change at any given time due to the
            fluctuating exchange rates.
          </small>
        </li>
        <li>
          <small>
            By continuing with this transaction you agree to the above as well
            as&nbsp;
            <a
              href="/account/ico?showTerms=true"
              className="text-primary"
              target="_blank"
              rel="noopener noreferrer"
            >
              terms and conditions
            </a>
          </small>
        </li>
      </ul>
      <div className="mb-3 mb-0">
        <label className="toggle">
          <input
            type="checkbox"
            className="toggle-checkbox"
            onChange={(e: any) => setAgree(e.target.checked)}
          />
          <div className="toggle-switch" />
          <span className="toggle-label">I understand and accept</span>
        </label>
      </div>
      <div className="d-block text-center mb-2 mt-2">
        <button
          type="submit"
          className="btn btn-primary bg-gradient-dark px-5"
          disabled={!allowSubmit || !agree}
        >
          CONFIRM &amp; SUBMIT
        </button>
      </div>
    </>
  );
};

const ConfirmBuyModal = (props: any) => {
  const {
    show,
    setShow,
    account,
    cbiRate,
    cbidRate,
    token_amount,
    markupPercentage,
    setShowOtpVerify,
    setTransaction,
    setPostData,
  } = props;
  const { handleSubmit } = useForm();
  const [currency, setCurrency] = useState<any>({});
  const [agree, setAgree] = useState<boolean>(false);
  const [usdAmount, setUsdAmount] = useState<number>(0);
  const [cbiAmount, setCbiAmount] = useState<number>(0);
  const [countdown, setCountdown] = useState<number>(30);
  const [allowSubmit, setAllowSubmit] = useState<boolean>(false);
  const [componentLoading, setComponentLoading] = useState<boolean>(true);
  const [currencyNetworkDetail, setCurrencyNetworkDetail] = useState<any>();

  async function fetchData() {
    if (!componentLoading) {
      setComponentLoading(true);
    }

    const currency: any = await CurrencyService.get('CBID');
    const [filtered] = currency.currency_networks.filter(
      (item: any) =>
        item.network_code === currency.currency_networks[0].network_code,
    );
    if (token_amount && token_amount > 0) {
      setAllowSubmit(true);
    } else {
      setShow(false);
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'System error! Sorry, please try again!',
        showConfirmButton: false,
        timer: 5000,
      });
      return setTimeout(async function () {
        // window.location.href = '/account/ico';
      }, 5000);
    }
    await calculate();
    setCurrency(currency);
    setCurrencyNetworkDetail(filtered);
    setComponentLoading(false);
  }

  useEffect(() => {
    setTransaction('ico-token-confirm.buy');
    fetchData();

    /**
     * START: Timer logic implementation
     * -----------------------------------------
     * Specify a 30 seconds timer, so that every
     * 30 seconds new buy price is calculated
     * based on new exchange rates.
     */
    let sec = 30;
    setInterval(() => {
      sec--;
      if (sec === 0) sec = 30;
      setCountdown(sec);
    }, 1000);

    const interval: any = setInterval(() => {
      calculate();
    }, 30000);

    /**
     * END: Timer logic implementation
     * -----------------------------------------
     */

    return () => clearInterval(interval);
  }, []);

  const toggle = () => setShow(!show);

  async function getExchRate(quote: string, base: string = 'USD') {
    const rate = await ExchangeService.rates(base, quote);
    return rate;
  }

  async function onSubmit() {
    setComponentLoading(true);
    const data: any = {
      type: 'ico',
      tx_type: 'debit',
      tokens: token_amount.toString(),
      account_id: account.id,
      subtype: 'ico_purchase',
      deposit_type: 'crypto',
      amount: cbiAmount.toString(),
      currency_code: currency.code,
    };

    setPostData(data);
    setShowOtpVerify(true);
    toggle();
  }

  const calculate = async () => {
    // get exchange rates
    const zarUsdRate: any = await getExchRate('ZAR');
    const gasFeeExchRate: any = await getExchRate('ZAR', 'MATIC');

    if (
      zarUsdRate && 
      gasFeeExchRate && 
      typeof zarUsdRate === 'number' && 
      typeof gasFeeExchRate === 'number'
    ) {

      // calculations
      const usdAmount: number = token_amount * cbidRate;
      const markupPrice: number = (zarUsdRate * markupPercentage) / 100;
      const zarAmount: number = (zarUsdRate + markupPrice) * usdAmount;
      const cbiAmount: number = zarAmount / cbiRate;
      const validate: any = await TransactionService.validateICOOrder(
        token_amount,
      );
      const { fee_estimate } = validate;
      const zarFeeAmount: number = fee_estimate * gasFeeExchRate;
      const cbiFeeAmount: number = zarFeeAmount / cbiRate;
      const inputAmount: number = cbiAmount + cbiFeeAmount;
      const usdInputAmount: number = (inputAmount * cbiRate) / zarUsdRate;
  
      // set values
      setUsdAmount(usdInputAmount);
      setCbiAmount(inputAmount);
  
      return inputAmount;
    }

    return calculate();
  };

  return (
    <div>
      <Modal isOpen={show} toggle={toggle} size="md" backdrop="static">
        {componentLoading && <Loader type="card" />}
        <ModalHeader toggle={toggle}>
          ({countdown < 10 ? `0${countdown}` : countdown})&nbsp; Buy your CBID
          token
        </ModalHeader>
        <ModalBody className="pt-0">
          <form onSubmit={handleSubmit(onSubmit)}>
            <PostDisclaimerForm
              agree={agree}
              currency={currency}
              setAgree={setAgree}
              tokens={token_amount}
              cbiAmount={cbiAmount}
              usdAmount={usdAmount}
              allowSubmit={allowSubmit}
              currencyNetwork={currencyNetworkDetail}
            />
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ConfirmBuyModal;
