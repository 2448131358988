import React from 'react';
import { Col, Row, Button } from 'reactstrap';

const ActionButtons = props => {
  const handleBack = () => {
    props.previousStep();
  };

  const handleNext = () => {
    props.nextStep();
  };

  const handleFinish = () => {
    props.lastStep();
  };

  return (
    <div className="kyc-action-buttons">
      <Row>
        {props.currentStep > 1 && (
          <Col>
            <Button
              onClick={handleBack}
              className="btn btn-outline-secondary btn-back"
            >
              <i className="fa fa-angle-left" />
              Back
            </Button>
          </Col>
        )}
        <Col className="text-right">
          {props.currentStep < props.totalSteps && (
            <Button
              onClick={handleNext}
              className="btn btn-success bg-gradient-dark btn-next"
              disabled={props.disabled || false}
            >
              Next
              <i className="fa fa-angle-right" />
            </Button>
          )}
          {props.currentStep === props.totalSteps && (
            <Button
              onClick={handleFinish}
              className="btn btn-success bg-gradient-dark btn-next"
              disabled={props.disabled || false}
            >
              Finish
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ActionButtons;
