import React, { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';

const fileTypes = ['JPG', 'PNG', 'PDF', 'JPEG'];

const Dropzone = (props: any) => {
  const { label, setFile, filename, maxSize, onSizeError } = props;
  const [pdf, setPdf] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<any>();

  const handleChange = (file: any) => {
    setFile(file);
    setPdf(file && file.type.includes('pdf'));

    // Assuming only image
    const img = URL.createObjectURL(file);
    setSelectedFile(img);
  };

  return (
    <div className="my-4 dropzone-container">
      {selectedFile && (
        <div className="preview">
          <img
            src={pdf ? '/images/icons/pdf-file.png' : selectedFile}
            alt="file"
          />
        </div>
      )}
      <FileUploader
        name={filename}
        multiple={false}
        types={fileTypes}
        hoverTitle="Drop here"
        handleChange={handleChange}
        onSizeError={(file: any) => onSizeError(file)}
        maxSize={maxSize || 5}
        classes="dropzone"
        label={label}
      />
    </div>
  );
};

export default Dropzone;
