import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { UserService } from '../../services/user.service';
import { useForm } from 'react-hook-form';
import Loader from '../common/loader';
import InputError from '../../components/form/error';
import validationMessages from '../../../static/validation-messages.json';

const errorMessages = validationMessages['token_address'];

const UpdateTokenWalletAddressModal = (props: any) => {
  const {
    reset,
    register,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const {
    show,
    setShow,
    setPageLoading,
    setPostData,
    setShowOtpVerify,
    setTransaction,
    walletAddress,
  } = props;
  const [agree, setAgree] = useState<boolean>(false);
  const [componentLoading, setComponentLoading] = useState<boolean>(false);

  async function fetchData() {
    setTransaction('token-wallet-address.update');
    setPageLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  const toggle = () => {
    setShow(!show);
    reset();
    setAgree(false);
    clearErrors('address');
  };

  async function onSubmit(data) {
    const { address } = data;

    // validate
    setComponentLoading(true);
    const response = await UserService.validateCryptoAccount({
      currency_code: 'CBID',
      address,
    });

    setComponentLoading(false);
    const { valid } = response;

    if (valid) {
      toggle();
      setPostData({
        ...data,
        channel: 'DAO',
        code: 'CBID',
        currency_code: 'CBID',
        name: 'CBID Token Wallet',
        type: 'on-ramping',
      });
      return setShowOtpVerify(true);
    }

    return setError('address', {
      type: 'custom',
      message: 'The provided wallet address does not match the Polygon network. Please provide a non-custodial wallet address from Polygon network.',
    });

  }

  return (
    <div>
      <Modal
        isOpen={show}
        toggle={toggle}
        size="md"
        backdrop="static"
        className="popup-modal"
      >
        {componentLoading && <Loader type="card" />}
        <ModalHeader toggle={toggle}>Your Token Wallet Address</ModalHeader>
        <ModalBody className="pt-0">
          <form onSubmit={handleSubmit(onSubmit)} className="signin_validate">
            <p>
              To update your token wallet addresss, please use the address
              input box below and click <strong>Save Changes</strong> to
              process your request.
            </p>
            <div className="mb-3">
              <label>Your address for tokens</label>
              <input
                type="text"
                className={`form-control ${errors.address ? 'is-invalid' : ''}`}
                defaultValue={walletAddress || ''}
                placeholder="Address for tokens"
                {...register('address', {
                  required: true,
                  validate: (value: any) => walletAddress !== value,
                })}
              />
              <InputError
                name="address"
                errors={errors}
                messages={errorMessages.address}
              />
              <span className="input-note">
                Note: Address should be Polygon-compliant.
              </span>
            </div>
            <div className="note note-plane text-danger mb-4">
              <em className="fa fa-info-circle" />
              <p>
                DO NOT USE your exchange wallet address such as Kraken,
                Bitfinex, Bithumb, Binance etc. You can use MetaMask,
                MayEtherWallet, Mist wallets etc. Do not use the address if you
                don't have a private key of the your address. You WILL NOT
                receive CBID Tokens and WILL LOSE YOUR FUNDS if you do.
              </p>
            </div>
            <div className="mb-3 mb-0">
              <label className="toggle">
                <input
                  type="checkbox"
                  className="toggle-checkbox"
                  onChange={(e: any) => setAgree(e.target.checked)}
                />
                <div className="toggle-switch" />
                <span className="toggle-label">
                  By submitting you agree to&nbsp;
                  <a
                    href="/account/ico?showTerms=true"
                    className="text-primary"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    terms and conditions
                  </a>
                </span>
              </label>
            </div>
            <div className="text-center mb-3">
              <button
                type="submit"
                className="btn btn-success bg-gradient-dark w-100"
                disabled={!agree}
              >
                Save Changes
              </button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default UpdateTokenWalletAddressModal;
