/**
 * Asynchronously loads the component for Account Pages
 */

import { lazyLoad } from 'utils/loadable';

export const AccountPage = lazyLoad(
  () => import('./index'),
  module => module.AccountPage,
);

export const ExchangePage = lazyLoad(
  () => import('./Exchange'),
  module => module.ExchangePage,
);

export const TransactionHistoryPage = lazyLoad(
  () => import('./TransactionHistory'),
  module => module.TransactionHistoryPage,
);

export const TransactionDetailsPage = lazyLoad(
  () => import('./TransactionDetails'),
  module => module.TransactionDetailsPage,
);

export const SettingsPage = lazyLoad(
  () => import('./Settings'),
  module => module.SettingsPage,
);

export const ICOPage = lazyLoad(
  () => import('./ICO'),
  module => module.ICOPage,
);

export const FeesPage = lazyLoad(
  () => import('./Fees'),
  module => module.FeesPage,
);
