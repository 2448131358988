import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { GenericService } from '../../services/generic.service';
import { StepOne, StepTwo, StepThree } from '../kyc/level-one';
import { GeoLocationService } from 'app/services/geolocation.service';
import { FileManagementService } from 'app/services/file.management.service';
import { UserService } from 'app/services/user.service';
import { _mask } from '../../../utils/fn';
import StepWizard from 'react-step-wizard';
import Loader from '../common/loader';
import Swal from 'sweetalert2';

const KYCLevelOneModal = (props: any) => {
  const { show, setShow, getApplications } = props;
  const [componentLoading, setComponentLoading] = useState<boolean>(true);
  const [selectedCountry, setSelectedCountry] = useState<any>({});
  const [identityType, setIdentityType] = useState<any>();
  const [countries, setCountries] = useState<any>([]);
  const [stepWizard, setStepWizard] = useState<any>(null);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [documentBack, setDocumentBack] = useState<any>();
  const [documentFront, setDocumentFront] = useState<any>();
  const [validationError, setValidationError] = useState<any>();
  const [profile, setProfile] = useState<any>();
  const [geoinfo, setGeoinfo] = useState<any>();

  async function getGeoInfo() {
    const geoinfo = await GeoLocationService.get();
    setGeoinfo(geoinfo);
  }

  async function fetchData() {
    const countries: any = await GenericService.get('countries');
    const profile: any = await UserService.profile();
    const [filtered] = countries.results.filter(
      (country: any) => country.iso === profile.nationality,
    );
    setProfile(profile);
    setSelectedCountry(filtered);
    setCountries(countries.results || []);
    setComponentLoading(false);
  }

  useEffect(() => {
    fetchData();
    getGeoInfo();
  }, []);

  const toggle = () => setShow(!show);

  const handleStepChange = e => {
    setActiveStep(e.activeStep - 1);
  };

  const handleComplete = async () => {
    const post: any = {
      geoinfo,
      level: 1,
      data: {
        document_type: identityType,
      },
    };
    switch (identityType) {
      /**
       * **********************************
       * Passport capture/upload logic
       * **********************************
       */
      case 'passport':
        /// validate if file(s) uploaded
        if (!documentFront) {
          return setValidationError(
            <p className="mb-0">
              Please choose {identityType} document file to upload.
            </p>,
          );
        }
        setComponentLoading(true);

        // handle upload passport file
        const passportFormData: any = new FormData();
        passportFormData.append('filename', documentFront);
        passportFormData.append('uid', profile.id);
        passportFormData.append('folder', 'kyc');

        post.documents = [];
        const passportUploadResponse = await FileManagementService.upload(
          passportFormData,
        );

        // check if docoument is uploaded successfully
        if (passportUploadResponse && passportUploadResponse.filename) {
          post.documents.push(passportUploadResponse.filename);

          // handle capture kyc record
          const passportCaptureResponse: any =
            await UserService.captureKYCApplication(post);
          setComponentLoading(false);

          if (passportCaptureResponse.success) {
            toggle();
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Request successfully processed!',
              showConfirmButton: false,
              timer: 4000,
            });
            return getApplications();
          }
        }

        return setValidationError(
          <p className="mb-0">Failed to process request, please try again!</p>,
        );

      /**
       * **************************************
       * ID (front & back) capture/upload logic
       * **************************************
       */

      default:
        /// validate if file(s) uploaded
        if (!documentFront) {
          return setValidationError(
            <p className="mb-0">
              Please choose {identityType} card front file to upload.
            </p>,
          );
        }
        if (!documentBack) {
          return setValidationError(
            <p className="mb-0">
              Please choose {identityType} card back file to upload.
            </p>,
          );
        }
        setComponentLoading(true);

        // handle upload identity document files
        const idFrontFormData: any = new FormData();
        idFrontFormData.append('filename', documentFront);
        idFrontFormData.append('uid', profile.id);
        idFrontFormData.append('folder', 'kyc');

        const idBackFormData: any = new FormData();
        idBackFormData.append('filename', documentBack);
        idBackFormData.append('uid', profile.id);
        idBackFormData.append('folder', 'kyc');

        post.documents = [];
        const idFrontUploadResponse = await FileManagementService.upload(
          idFrontFormData,
        );
        const idBackUploadResponse = await FileManagementService.upload(
          idBackFormData,
        );

        // check if docoument is uploaded successfully
        if (
          idFrontUploadResponse &&
          idBackUploadResponse &&
          idFrontUploadResponse.filename &&
          idBackUploadResponse.filename
        ) {
          post.documents.push(idFrontUploadResponse.filename);
          post.documents.push(idBackUploadResponse.filename);

          // handle capture kyc record
          const idCaptureResponse: any =
            await UserService.captureKYCApplication(post);
          setComponentLoading(false);

          if (idCaptureResponse.success) {
            toggle();
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Request successfully processed!',
              showConfirmButton: false,
              timer: 4000,
            });
            return getApplications();
          }
        }

        setComponentLoading(false);

        return setValidationError(
          <p className="mb-0">
            Oops, something happened from our side, please try again!
          </p>,
        );
    }
  };

  const assignStepWizard = (instance: any) => {
    setStepWizard(instance);
  };

  return (
    <div>
      <Modal isOpen={show} toggle={toggle} size="md" backdrop="static">
        {componentLoading && <Loader type="card" />}
        <ModalHeader toggle={toggle}>KYC Level 1 Application</ModalHeader>
        <ModalBody>
          <div className="kyc-wrapper">
            <StepWizard
              instance={assignStepWizard}
              onStepChange={handleStepChange}
            >
              <StepOne
                profile={profile}
                countries={countries}
                setIdentityType={setIdentityType}
                selectedCountry={selectedCountry}
                setSelectedCountry={setSelectedCountry}
              />
              <StepTwo identityType={identityType} />
              <StepThree
                error={validationError}
                identityType={identityType}
                setError={setValidationError}
                completeCallback={handleComplete}
                setDocumentBack={setDocumentBack}
                setDocumentFront={setDocumentFront}
              />
            </StepWizard>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default KYCLevelOneModal;
