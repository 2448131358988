import React from 'react';
import { ModalBody } from 'reactstrap';

const CryptoRiskDisclaimer = (props: any) => {
  const { setShowDisclaimer, currencyCode } = props;
  return (
    <ModalBody className="text-center">
      <img
        src="/images/icons/warning.png"
        className="icon my-3"
        alt="Warning"
      />
      <h4>
        <strong style={{ color: '#F9C048' }}>RISK OF LOSING FUNDS</strong>
      </h4>
      <p className="mx-3">
        <strong>DO NOT</strong> make a crypto transfer before performing a buy
        request on CBI-X <strong>FIRST</strong>. Make sure that you{' '}
        <strong>ONLY</strong> action your crypto transfer (from third-party
        exchange) to your CBI-X receiving address <strong>AFTER</strong>
        &nbsp;performing a buy request here on the CBI-X. Failure to do so will
        carry a <strong>5% PENALTY FEE</strong> per buy request transaction.
      </p>
      <p>
        Please note that after submitting your buy request, you only have&nbsp;
        <strong>up to 3 hours</strong> to transfer crypto to the CBI-X receiving
        address, before your transaction is rejected.
      </p>
      <button
        type="button"
        className="btn btn-success w-100 bg-gradient-dark px-4"
        onClick={() => setShowDisclaimer(false)}
      >
        Got it, proceed
      </button>
    </ModalBody>
  );
};

export default CryptoRiskDisclaimer;
