import PasswordValidator from 'password-validator';

export function _sufficientBalance(wallet) {
  return wallet && wallet.available_balance && wallet.available_balance > 0
    ? true
    : false;
}

export function _mask(str, prelen = 8, postlen = 8) {
  const split1 = str.substring(0, prelen);
  const split2 = str.substring(str.length - postlen);
  return split1 + '*'.repeat(str.length - prelen - postlen) + split2;
}

export function _maskEmail(email) {
  let split = email.split('@');
  let split1 = split[0];
  let avg = split1.length / 2;
  split1 = split1.substring(0, split1.length - avg);
  var split2 = split[1];
  return split1 + '******@' + split2;
}

export function validatePassword(password) {
  const passwordValidationSchema = new PasswordValidator();

  // Password validation schema
  passwordValidationSchema
    .is()
    .min(8) // Minimum length 8
    .is()
    .max(100) // Maximum length 100
    .has()
    .uppercase() // Must have uppercase letters
    .has()
    .lowercase() // Must have lowercase letters
    .has()
    .digits(1) // Must have at least 1 digit
    .has()
    .symbols(1) // Should have atleast one special character
    .has()
    .not()
    .spaces(); // Should not have spaces
  // .is().not().oneOf(['Passw0rd', 'Password123']); // Blacklist these values

  return passwordValidationSchema.validate(password);
}
