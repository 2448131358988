import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CurrencyService } from '../../services/currency.service';
import { CalculationsService } from '../../services/calculations.service';
import { ExchangeService } from '../../services/exchange.service';
import { QrCodeService } from '../../services/qrcode.service';
import { TransactionService } from '../../services/transaction.service';
import { UserService } from '../../services/user.service';
import { _mask } from '../../../utils/fn';
import RiskDisclaimer from '../deposit/crypto-risk-disclaimer';
import Loader from '../common/loader';
import Swal from 'sweetalert2';

/**
 * This will hide/show the following:
 * - Deposit QRCode
 * - Deposit Address
 */
const showBuyInfo = false;

const CryptoDepositModal = (props: any) => {
  const { show, amount, setShow, setAmount, currencyCode, currencyNetwork } =
    props;
  const [currency, setCurrency] = useState<any>();
  const [account, setAccount] = useState<any>();
  const [agree, setAgree] = useState<boolean>(false);
  const [amountInCBI, setAmountInCBI] = useState<any>(0.0);
  const [showQrCode, setShowQrCode] = useState<boolean>(true);
  const [depositAddress, setDepositAddress] = useState<string>();
  const [depositQrCode, setDepositQrCode] = useState<string>();
  const [componentLoading, setComponentLoading] = useState<boolean>(true);
  const [showRiskDisclaimer, setShowRiskDisclaimer] = useState<boolean>(true);
  const [currencyNetworkDetail, setCurrencyNetworkDetail] = useState<any>();
  const [allowSubmit, setAllowSubmit] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<number>(30);
  const history: any = useHistory();

  async function fetchData() {
    const account: any = await UserService.account(currencyCode);
    if (account && account.address) {
      setAccount(account);
      const depositQrCode = await QrCodeService.get(account.address);
      setDepositAddress(account.address);
      setDepositQrCode(depositQrCode);
    }
    const currency: any = await CurrencyService.get(currencyCode);
    const cryptoRate: any = await ExchangeService.rates(currencyCode, 'ZAR');
    const cryptoValue: any = amount / cryptoRate;
    const divisibility: any = (currency && currency.divisibility) || 6;
    const cryptoAmount: any = cryptoValue.toFixed(divisibility);
    setAmount(cryptoAmount);

    const amountInCBI: any = await CalculationsService.deposit(
      cryptoAmount,
      currencyCode,
    );
    if (amountInCBI && parseFloat(amountInCBI) > 0) {
      setAllowSubmit(true);
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'System error! Sorry, please try again!',
        showConfirmButton: false,
        timer: 4000,
      });
      return setTimeout(async function () {
        window.location.href = '/account';
      }, 4000);
    }

    const [filtered] = currency.currency_networks.filter(
      (item: any) => item.network_code === currencyNetwork,
    );
    setCurrencyNetworkDetail(filtered);
    setAmountInCBI(amountInCBI);
    setCurrency(currency);
    setComponentLoading(false);
  }

  async function reCalculatePrice() {
    const cryptoRate: any = await ExchangeService.rates(currencyCode, 'ZAR');
    const cryptoValue: any = amount / cryptoRate;

    if (parseFloat(cryptoValue) > 0) {
      const divisibility: any = (currency && currency.divisibility) || 6;
      const cryptoAmount: any = cryptoValue.toFixed(divisibility);
      const amountInCBI: any = await CalculationsService.deposit(
        cryptoAmount,
        currencyCode,
      );
      setAmountInCBI(amountInCBI);
    }
  }

  useEffect(() => {
    fetchData();

    /**
     * START: Timer logic implementation
     * -----------------------------------------
     * Specify a 30 seconds timer, so that every
     * 30 seconds new buy price is calculated
     * based on new exchange rates.
     */
    let sec = 30;
    setInterval(() => {
      sec--;
      if (sec === 0) sec = 30;
      setCountdown(sec);
    }, 1000);

    const interval: any = setInterval(() => {
      reCalculatePrice();
    }, 30000);

    /**
     * END: Timer logic implementation
     * -----------------------------------------
     */

    return () => clearInterval(interval);
  }, []);

  const toggle = () => setShow(!show);

  const hanndleCopyAddress = () => {
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Address copied to clipboard',
      showConfirmButton: false,
      timer: 2000,
    });
  };

  const handleDepositMade = async () => {
    setComponentLoading(true);
    const data: any = {
      currency_network: currencyNetwork,
      currency_code: currencyCode,
      account_id: account.id,
      subtype: 'deposit',
      tx_type: 'credit',
      type: 'crypto',
      amount,
    };

    const response: any = await TransactionService.create(data);
    const { success, message } = response;

    setComponentLoading(false);
    toggle();

    if (success) {
      const { transaction_id } = response.data;
      Swal.fire({
        position: 'center',
        icon: 'success',
        title:
          'Request processed successfully! Please complete the transaction on the next screen!',
        showConfirmButton: false,
        timer: 10000,
      });
      return setTimeout(async function () {
        history.push(`/account/transactions/${transaction_id}`);
      }, 10000);
    }

    Swal.fire({
      position: 'center',
      icon: 'error',
      title: message || 'Failed to process request, please try again!',
      showConfirmButton: false,
      timer: 5000,
    });
  };

  return (
    <div>
      <Modal isOpen={show} toggle={toggle} size="md" backdrop="static">
        {componentLoading && <Loader type="card" />}
        {showRiskDisclaimer ? (
          <RiskDisclaimer
            setShowDisclaimer={setShowRiskDisclaimer}
            currencyCode={currencyCode}
          />
        ) : (
          <>
            <ModalHeader toggle={toggle}>
              ({countdown})&nbsp;
              {agree ? 'Buy your CBI token' : 'Before you buy, please note:'}
            </ModalHeader>
            <ModalBody className="pt-0">
              {agree ? (
                <>
                  <div className="buyer-seller">
                    <small className="mt-3 mb-2 d-block">
                      Sign in to your trading platform APP or wallet APP, and
                      send {currency.code} using the information below:
                    </small>
                    <div className="table-responsive">
                      <table className="table mb-0">
                        <tbody>
                          <tr>
                            <td className="deposit-details">
                              <div className="row">
                                <small>CRYPTO CURRENCY</small>
                                <label>
                                  {currency && (
                                    <>
                                      {currency.code} - {currency.description} (
                                      {currency.symbol})
                                    </>
                                  )}
                                </label>
                              </div>
                              {currencyNetwork && currency.code === 'USDT' && (
                                <div className="row">
                                  <small>CHOSEN {currency.code} NETWORK</small>
                                  <label>
                                    {currencyNetworkDetail.network.description}
                                    &nbsp; ({currencyNetworkDetail.network.code}
                                    )
                                  </label>
                                </div>
                              )}
                              {showBuyInfo && (
                                <div className="row">
                                  <small>ADDRESS</small>
                                  <label>
                                    <div
                                      className="d-flex"
                                      style={{
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      {depositAddress && (
                                        <span className="me-3">
                                          {_mask(depositAddress)}
                                        </span>
                                      )}
                                      <div>
                                        <CopyToClipboard
                                          text={depositAddress}
                                          onCopy={hanndleCopyAddress}
                                          className="me-3 pointer-on-hover"
                                        >
                                          <span>
                                            <i className="fa fa-copy" />
                                          </span>
                                        </CopyToClipboard>
                                        <a
                                          href=" "
                                          className="pointer-on-hover"
                                          onClick={(e: any) => {
                                            e.preventDefault();
                                            setShowQrCode(!showQrCode);
                                          }}
                                        >
                                          <i className="fa fa-qrcode" />
                                        </a>
                                      </div>
                                    </div>
                                  </label>
                                </div>
                              )}
                              <div className="row">
                                <small>AMOUNT TO SEND</small>
                                <label>
                                  {amount} {currency.code} + Your Network
                                  Exchange Fee
                                  {currency.code === 'USDT' && (
                                    <> + Additional Transfer Fee</>
                                  )}
                                </label>
                              </div>
                              <div className="row">
                                <small>ESTIMATED AMOUNT YOU WILL RECEIVE</small>
                                <label>{amountInCBI} CBI</label>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {showBuyInfo && (
                    <div className="mb-3 text-center d-block">
                      <div
                        className="my-2"
                        style={
                          showQrCode
                            ? { display: 'block' }
                            : { display: 'none' }
                        }
                      >
                        <img src={depositQrCode} alt="qrcode" />
                      </div>
                      [
                      <span
                        role="button"
                        className="text-primary"
                        onClick={() => setShowQrCode(!showQrCode)}
                      >
                        {showQrCode ? 'hide qrcode' : 'show qrcode'}
                      </span>
                      ]
                    </div>
                  )}
                  <ul className="unordered-list mb-3">
                    {currency && (
                      <>
                        <li>
                          <small>
                            Send only <strong>{currency.code}</strong> to the
                            above crypto address.
                          </small>
                        </li>
                        <li>
                          <small>
                            Make sure that the network is{' '}
                            <strong style={{ textTransform: 'capitalize' }}>
                              {currency.description} (
                              {currency.currency_networks[0].network_code})
                            </strong>
                          </small>
                        </li>
                      </>
                    )}
                    <li>
                      <small>
                        <strong>
                          Once you have confirmed this transaction, you will be
                          redirected to the next page, where you can complete
                          your transaction.
                        </strong>
                      </small>
                    </li>
                    <li>
                      <small>
                        The quoted amount may change at any given time due to
                        the fluctuating exchange rates of {currencyCode}.
                      </small>
                    </li>
                    <li>
                      <small>
                        The quoted amount is only fixed for 10 minutes from the
                        time this transaction is submitted.
                      </small>
                    </li>
                    <li>
                      <small>
                        <strong>
                          Funds into your CBI-X crypto wallet will only be
                          released after 3 blockchain confirmations.
                        </strong>
                      </small>
                    </li>
                    <li>
                      <small>
                        After submitting your buy request, you only have{' '}
                        <strong>up to 3 hours</strong> to transfer{' '}
                        {currencyCode || 'funds'}
                        &nbsp;to the CBI-X receiving address, before your
                        transaction is rejected.
                      </small>
                    </li>
                  </ul>
                  <div className="d-block text-center mb-2 mt-2">
                    <button
                      type="button"
                      className="btn btn-primary bg-gradient-dark px-5"
                      onClick={handleDepositMade}
                      disabled={!allowSubmit}
                    >
                      CONFIRM &amp; CONTINUE
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <ul className="unordered-list mb-3 mt-3">
                    {currency && (
                      <>
                        <li>
                          <small>
                            Make sure that the network you choose to is{' '}
                            <strong>
                              {currency.description} (
                              {currency.currency_networks[0].network_code})
                            </strong>
                          </small>
                        </li>
                        <li>
                          <small>
                            The network selection depends on the options
                            provided by the external wallet/exchange that you
                            are making the withdrawal from. If the external
                            platform only supports{' '}
                            {currency.currency_networks[0].network_code}, you
                            must select the{' '}
                            {currency.currency_networks[0].network_code} deposit
                            network.
                          </small>
                        </li>
                        <li>
                          <small>
                            DO NOT select the cheapest fee option. Select the
                            one that is compatible with the external platform.
                            For example, you can only send{' '}
                            {currency.currency_networks[0].network_code} tokens
                            to another&nbsp;
                            {currency.currency_networks[0].network_code}{' '}
                            address. If you select incompatible/different
                            deposit networks, you will lose your funds.
                          </small>
                        </li>
                        <li>
                          <small>
                            After submitting your buy request, you only have{' '}
                            <strong>up to 3 hours</strong> to transfer{' '}
                            {currencyCode || 'funds'}
                            &nbsp;to the CBI-X receiving address, before your
                            transaction is rejected.
                          </small>
                        </li>
                      </>
                    )}
                  </ul>
                  <div className="mb-3 mb-0">
                    <label className="toggle">
                      <input
                        type="checkbox"
                        className="toggle-checkbox"
                        onChange={(e: any) => setAgree(e.target.checked)}
                      />
                      <div className="toggle-switch" />
                      <span className="toggle-label">
                        I understand and accept
                      </span>
                    </label>
                  </div>
                </>
              )}
            </ModalBody>
          </>
        )}
      </Modal>
    </div>
  );
};

export default CryptoDepositModal;
