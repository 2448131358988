import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CalculationsService } from '../../services/calculations.service';
import { CurrencyService } from '../../services/currency.service';
import { TransactionService } from '../../services/transaction.service';
import { UserService } from '../../services/user.service';
import { _mask } from '../../../utils/fn';
import validator from 'validator';
import Loader from '../common/loader';
import Swal from 'sweetalert2';
import InputError from '../../components/form/error';
import validationMessages from '../../../static/validation-messages.json';

const errorMessages = validationMessages['dafribank_buy'];

const DafriBankDepositModal = props => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { show, setShow, currencyCode, amount, profile } = props;
  const [agree, setAgree] = useState<boolean>(false);
  const [wallet, setWallet] = useState<any>();
  const [postData, setPostData] = useState<any>();
  const [currency, setCurrency] = useState<any>(null);
  const [referenceNumber] = useState<any>(profile.email);
  const [depositAccountNumber] = useState<any>('5060088129');
  const [amountInCBI, setAmountInCBI] = useState<any>(0.0);
  const [componentLoading, setComponentLoading] = useState<boolean>(true);
  const [transactionIdPrompt, setTransactionIdPrompt] =
    useState<boolean>(false);
  const [showThankYou, setShowThankYou] = useState<boolean>(false);
  const history = useHistory();

  async function fetchData() {
    const wallet: any = await UserService.wallet();
    const currency = await CurrencyService.get(currencyCode);
    const amountInCBI = await CalculationsService.deposit(amount, currencyCode);
    setAmountInCBI(amountInCBI);
    setCurrency(currency);
    setWallet(wallet);
    setComponentLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  const toggle = () => setShow(!show);

  const close = () => history.push('/account/transactions');

  const hanndleCopyAccountNumber = () => {
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Number copied to clipboard',
      showConfirmButton: false,
      timer: 2000,
    });
  };

  const hanndleReference = () => {
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Reference copied to clipboard',
      showConfirmButton: false,
      timer: 2000,
    });
  };

  const handleSubmitDeposit = async (data: any) => {
    let message = null;

    setTransactionIdPrompt(false);

    if (postData && postData.amount) {
      setComponentLoading(true);
      const response: any = await TransactionService.create({
        ...data,
        ...postData,
      });
      const { success } = response;

      setComponentLoading(false);

      if (success) {
        return setShowThankYou(true);
      }
      message =
        response.message || 'Failed to process request, please try again!';
    }

    toggle();

    Swal.fire({
      position: 'center',
      icon: 'error',
      title: message || 'Failed to process request, please try again!',
      showConfirmButton: false,
      timer: 4000,
    });
  };

  const handleDepositMade = async () => {
    setPostData({
      currency_code: currencyCode,
      account_id: wallet.id,
      tx_type: 'credit',
      subtype: 'deposit',
      type: 'bank',
      amount,
    });
    setTransactionIdPrompt(true);
  };

  return (
    <div>
      <Modal isOpen={show} toggle={toggle} size="md" backdrop="static">
        {componentLoading && <Loader type="card" />}
        {transactionIdPrompt ? (
          <>
            <ModalHeader toggle={toggle}>Before you submit</ModalHeader>
            <ModalBody className="pt-0">
              <form
                onSubmit={handleSubmit(handleSubmitDeposit)}
                className="signin_validate"
              >
                <p className="mt-3">
                  To confirm that you have made a transfer for this transaction
                  to Dafribank, please provide us with a DafriBank Transaction
                  ID for this transaction.
                </p>
                <div className="mb-3 mt-3">
                  <label className="me-sm-2 mb-2">Transaction ID</label>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.transaction_id ? 'is-invalid' : ''
                    }`}
                    placeholder="Enter DafriBank Transaction ID"
                    {...register('transaction_id', {
                      required: true,
                      minLength: 3,
                      validate: value => validator.isNumeric(value),
                    })}
                  />
                  {errors.transaction_id ? (
                    <InputError
                      name="transaction_id"
                      errors={errors}
                      messages={errorMessages.transaction_id}
                    />
                  ) : (
                    <span
                      className="d-block mt-2"
                      style={{ lineHeight: '17px' }}
                    >
                      <small className="hint-text" style={{ lineHeight: 1 }}>
                        By confirming and submitting you confirm that you have
                        verified that Transaction ID specified above is correct.
                      </small>
                    </span>
                  )}
                </div>
                <div className="d-block text-center mb-2 mt-4">
                  <button
                    type="submit"
                    className="btn btn-primary bg-gradient-dark px-5 me-3"
                  >
                    CONFIRM &amp; SUBMIT
                  </button>
                  <a role="button" onClick={toggle}>
                    Cancel
                  </a>
                </div>
              </form>
            </ModalBody>
          </>
        ) : (
          <>
            <ModalHeader toggle={toggle}>
              {showThankYou ? (
                <>Awesome, we'll await bank confirmation</>
              ) : (
                <>
                  {agree
                    ? 'Make your transfer'
                    : 'Before you transfer, please note:'}
                </>
              )}
            </ModalHeader>
            <ModalBody className="pt-0">
              {showThankYou ? (
                <div className="mt-3">
                  <p>This usually takes up to 2-3 business days to reflect.</p>
                  <p>
                    As soon as the bank transfer reflects,&nbsp;
                    <strong>we will let you know</strong>
                    &nbsp;and add it straight to your crypto wallet.
                  </p>
                  <p>
                    To speed up the process you can email your proof of transfer
                    to&nbsp;
                    <a href="mailto:pop@cbi-x.io" className="text-primary">
                      pop@cbi-x.io
                    </a>
                    .
                  </p>
                  <div className="d-block text-center mb-1 mt-3">
                    <button
                      type="button"
                      className="btn btn-primary bg-gradient-dark px-4 me-1"
                      onClick={close}
                    >
                      GOT IT
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  {agree ? (
                    <>
                      <div className="buyer-seller">
                        <small className="mt-3 mb-2 d-block">
                          Sign in to your Dafribank account, and transfer funds
                          using the information below:
                        </small>
                        <div className="table-responsive">
                          <table className="table mb-0">
                            <tbody>
                              <tr>
                                <td className="deposit-details">
                                  <div className="row">
                                    <small>HOLDER NAME</small>
                                    <label>CBI Exchange Namibia</label>
                                  </div>
                                  <div className="row">
                                    <small>BANK NAME</small>
                                    <label>Dafribank</label>
                                  </div>
                                  <div className="row">
                                    <small>ACCOUNT NUMBER</small>
                                    <label>
                                      {depositAccountNumber}
                                      &nbsp;&nbsp;
                                      <CopyToClipboard
                                        text={depositAccountNumber}
                                        onCopy={hanndleCopyAccountNumber}
                                        className="ml-3 pointer-on-hover"
                                      >
                                        <span>
                                          <i className="fa fa-copy" />
                                        </span>
                                      </CopyToClipboard>
                                    </label>
                                  </div>
                                  <div className="row">
                                    <small>REFERENCE TO USE</small>
                                    <label>
                                      {referenceNumber}
                                      &nbsp;&nbsp;
                                      <CopyToClipboard
                                        text={referenceNumber}
                                        onCopy={hanndleReference}
                                        className="ml-3 pointer-on-hover"
                                      >
                                        <span>
                                          <i className="fa fa-copy" />
                                        </span>
                                      </CopyToClipboard>
                                    </label>
                                  </div>
                                  <div className="row">
                                    <small>AMOUNT TO TRANSFER</small>
                                    <label>
                                      {amount} {currency.code}
                                    </label>
                                  </div>
                                  <div className="row">
                                    <small>AMOUNT YOU WILL RECEIVE</small>
                                    <label>{amountInCBI} CBI</label>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <ul className="unordered-list mb-3">
                        <li>
                          <small>
                            Note: 1 Namibian Dollar (NAD) = 1 South African Rand
                            (ZAR)
                          </small>
                        </li>
                        <li>
                          <small>
                            Copy the above account email to make a transfer.
                          </small>
                        </li>
                        <li>
                          <small>
                            Only use <strong>{referenceNumber}</strong> as
                            reference
                          </small>
                        </li>
                        <li>
                          <small>
                            By continuing with this transaction you agree
                            to&nbsp;
                            <a
                              href="/terms"
                              className="text-primary"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              CBI-X Terms and Conditions
                            </a>
                          </small>
                        </li>
                      </ul>
                      <div className="d-block text-center mb-2 mt-2">
                        <button
                          type="button"
                          className="btn btn-primary bg-gradient-dark px-5 me-1"
                          onClick={handleDepositMade}
                        >
                          CONTINUE
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <ul className="unordered-list mb-3 mt-3">
                        <li>
                          <small>
                            Use your Dafribank account to transfer funds to the
                            CBI-X Dafribank account.
                          </small>
                        </li>
                        <li>
                          <small>
                            Transfers take up to 3 business days to reflect.
                          </small>
                        </li>
                        <li>
                          <small>
                            Transfer only to the CBI-X{' '}
                            <strong>Dafribank</strong> account.
                          </small>
                        </li>
                        <li>
                          <small>
                            Only use <strong>{referenceNumber}</strong> as
                            reference
                          </small>
                        </li>
                        <li>
                          <small>
                            By continuing with this transaction you agree
                            to&nbsp;
                            <a
                              href="/terms"
                              className="text-primary"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              CBI-X Terms and Conditions
                            </a>
                          </small>
                        </li>
                        <li>
                          <small>
                            To speed up process, email proof of payment
                            <br />
                            to&nbsp;
                            <a
                              href="mailto:pop@cbi-x.io"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-primary"
                            >
                              pop@cbi-x.io
                            </a>
                          </small>
                        </li>
                      </ul>
                      <div className="mb-3 mb-0">
                        <label className="toggle">
                          <input
                            type="checkbox"
                            className="toggle-checkbox"
                            onChange={(e: any) => setAgree(e.target.checked)}
                          />
                          <div className="toggle-switch" />
                          <span className="toggle-label">
                            I understand and accept
                          </span>
                        </label>
                      </div>
                    </>
                  )}
                </>
              )}
            </ModalBody>
          </>
        )}
      </Modal>
    </div>
  );
};

export default DafriBankDepositModal;
