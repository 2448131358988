import axios from 'axios';
import config from '../config';

const headers: any = {
  'Content-Type': 'application/json',
};

export class FileManagementService {
  /**
   * Base url
   */
  static baseurl = config.services.fileManagement;

  /**
   * Upload file
   * @param {object} data
   * @returns
   */
  static async upload(data: any) {
    const options: any = {
      method: 'POST',
      mode: 'no-cors',
      url: `${this.baseurl}upload`,
      crossdomain: true,
      headers,
      data,
    };
    return await axios(options)
      .then(json => json.data)
      .then(res => res.data || {})
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }
}
