import axios from 'axios';
import { osName, osVersion, browserName, isMobile } from 'react-device-detect';
import { Session } from './session.service';
import config from '../config';

const token = Session.getToken();
const headers = {
  Authorization: `Bearer ${token}`,
  'Content-Type': 'application/json',
};

export class AuthService {
  /**
   * Base url
   */
  static baseurl = config.services.auth;

  /**
   * User registration
   *
   * @param   {object} data
   * @returns {object}
   */
  static async register(data: any) {
    data.device = {
      browser: browserName,
      os_name: osName,
      os_version: osVersion,
      is_mobile: isMobile,
    };
    const options: any = {
      data,
      method: 'POST',
      mode: 'no-cors',
      url: `${this.baseurl}register`,
      crossdomain: true,
      headers: {
        'Content-Type': 'application/json',
      },
    };
    return await axios(options)
      .then(json => json.data)
      .then(res => res)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  /**
   * Validate token
   *
   * @param   {string} token
   * @returns {object}
   */
  static async validateToken(token: any, key: any = 'account.activation') {
    const options: any = {
      method: 'POST',
      mode: 'no-cors',
      url: `${this.baseurl}token/validate`,
      crossdomain: true,
      data: { key },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    return await axios(options)
      .then(json => json.data)
      .then(res => res)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  /**
   * User activate account
   *
   * @param   {string} token
   * @param   {object} data
   * @param   {object} geoinfo
   * @returns {object}
   */
  static async activate(token: string, data: any = {}, geoinfo: any = {}) {
    data.geoinfo = geoinfo;
    data.device = {
      browser: browserName,
      os_name: osName,
      os_version: osVersion,
      is_mobile: isMobile,
    };
    const options: any = {
      data,
      method: 'POST',
      mode: 'no-cors',
      url: `${this.baseurl}activate`,
      crossdomain: true,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    return await axios(options)
      .then(json => json.data)
      .then(res => res)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  /**
   * User activate account
   *
   * @param   {string} token
   * @param   {object} data
   * @param   {object} geoinfo
   * @returns {object}
   */
  static async resendActivationEmail(token: string, geoinfo: any = {}) {
    const data: any = { token, geoinfo };
    data.device = {
      browser: browserName,
      os_name: osName,
      os_version: osVersion,
      is_mobile: isMobile,
    };
    const options: any = {
      data,
      method: 'POST',
      mode: 'no-cors',
      url: `${this.baseurl}activate/resend/email`,
      crossdomain: true,
      headers: {
        'Content-Type': 'application/json',
      },
    };
    return await axios(options)
      .then(json => json.data)
      .then(res => res)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  /**
   * User login
   *
   * @param   {string} user
   * @param   {string} password
   * @param   {object} geoinfo
   * @returns {object}
   */
  static async login(user: string, password: string, geoinfo: any = {}) {
    const data = {
      channel: 'DAO',
      user,
      password,
      geoinfo,
      device: {
        browser: browserName,
        os_name: osName,
        os_version: osVersion,
        is_mobile: isMobile,
      },
    };
    const options: any = {
      data,
      method: 'POST',
      mode: 'no-cors',
      url: `${this.baseurl}login`,
      crossdomain: true,
      headers: {
        'Content-Type': 'application/json',
      },
    };
    return await axios(options)
      .then(json => json.data)
      .then(res => res)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  /**
   * User verify login
   *
   * @param   {string} token
   * @param   {string} code
   * @param   {object} geoinfo
   * @returns {object}
   */
  static async verifyLogin(token: string, code: any, geoinfo: any = {}) {
    const data = {
      channel: 'DAO',
      code,
      geoinfo,
      device: {
        browser: browserName,
        os_name: osName,
        os_version: osVersion,
        is_mobile: isMobile,
      },
    };
    const options: any = {
      data,
      method: 'POST',
      mode: 'no-cors',
      url: `${this.baseurl}login/verify`,
      crossdomain: true,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    return await axios(options)
      .then(json => json.data)
      .then(res => res)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  /**
   * User verify login Resend
   *
   * @param   {string} token
   * @param   {string} code
   * @param   {object} geoinfo
   * @returns {object}
   */
  static async verifyLoginResend(token: string, geoinfo: any = {}) {
    const data = {
      token,
      geoinfo,
      device: {
        browser: browserName,
        os_name: osName,
        os_version: osVersion,
        is_mobile: isMobile,
      },
    };
    const options: any = {
      data,
      method: 'POST',
      mode: 'no-cors',
      url: `${this.baseurl}login/verify/resend`,
      crossdomain: true,
      headers: {
        'Content-Type': 'application/json',
      },
    };
    return await axios(options)
      .then(json => json.data)
      .then(res => res)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  /**
   * User reset password
   *
   * @param   {string} email
   * @param   {object} geoinfo
   * @returns {object}
   */
  static async resetPassword(email: string, geoinfo: any = {}) {
    const data = {
      email,
      geoinfo,
      device: {
        browser: browserName,
        os_name: osName,
        os_version: osVersion,
        is_mobile: isMobile,
      },
    };
    const options: any = {
      data,
      method: 'POST',
      mode: 'no-cors',
      url: `${this.baseurl}password/reset`,
      crossdomain: true,
      headers: {
        'Content-Type': 'application/json',
      },
    };
    return await axios(options)
      .then(json => json.data)
      .then(res => res)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  /**
   * User reset password confirmation
   *
   * @param   {object} data
   * @param   {object} geoinfo
   * @returns {object}
   */
  static async confirmResetPassword(data: any, token: string) {
    data.device = {
      browser: browserName,
      os_name: osName,
      os_version: osVersion,
      is_mobile: isMobile,
    };
    const options: any = {
      data,
      method: 'POST',
      mode: 'no-cors',
      url: `${this.baseurl}password/reset/confirm`,
      crossdomain: true,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    return await axios(options)
      .then(json => json.data)
      .then(res => res)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  /**
   * User change password
   *
   * @param   {object} data
   * @returns {object}
   */
  static async changePassword(data: any) {
    data.device = {
      browser: browserName,
      os_name: osName,
      os_version: osVersion,
      is_mobile: isMobile,
    };
    const options: any = {
      data,
      method: 'POST',
      mode: 'no-cors',
      url: `${this.baseurl}password/change`,
      crossdomain: true,
      headers,
    };
    return await axios(options)
      .then(json => json.data)
      .then(res => res)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  /**
   * User verify OTP
   *
   * @param   {object} data
   * @returns {object}
   */
  static async sendOTP(data: any) {
    const options: any = {
      data,
      method: 'POST',
      mode: 'no-cors',
      url: `${this.baseurl}otp`,
      crossdomain: true,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    return await axios(options)
      .then(json => json.data)
      .then(res => res)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  /**
   * User verify OTP
   *
   * @param   {object} data
   * @returns {object}
   */
  static async verifyOtp(data: any) {
    const options: any = {
      data,
      method: 'POST',
      mode: 'no-cors',
      url: `${this.baseurl}otp/verify`,
      crossdomain: true,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    return await axios(options)
      .then(json => json.data)
      .then(res => res)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  /**
   * User auth
   *
   * @param   {object} data
   * @returns {object}
   */
  static async auth(token: string, params: any = {}) {
    const options: any = {
      method: 'POST',
      mode: 'no-cors',
      url: this.baseurl,
      crossdomain: true,
      data: { get: true },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    return await axios(options)
      .then(json => json.data)
      .then(res => res)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  /**
   * Send mobile verify otp
   *
   * @param   {string} token
   * @param   {object} data
   * @returns {object}
   */
  static async sendMobileVerifyOTP(token: string, data: any = {}) {
    const options: any = {
      data,
      method: 'POST',
      mode: 'no-cors',
      url: `${this.baseurl}mobile/verify/send`,
      crossdomain: true,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    return await axios(options)
      .then(json => json.data)
      .then(res => res)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  /**
   * User verify mobile number
   *
   * @param   {string} token
   * @param   {string} code
   * @param   {object} geoinfo
   * @returns {object}
   */
  static async verifyMobile(token: string, code: any, geoinfo: any = {}) {
    const data = {
      code,
      geoinfo,
      device: {
        browser: browserName,
        os_name: osName,
        os_version: osVersion,
        is_mobile: isMobile,
      },
    };
    const options: any = {
      data,
      method: 'POST',
      mode: 'no-cors',
      url: `${this.baseurl}mobile/verify`,
      crossdomain: true,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    return await axios(options)
      .then(json => json.data)
      .then(res => res)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  /**
   * User verify mobile number
   *
   * @param   {string} token
   * @returns {object}
   */
  static async resetToken(token: string) {
    const options: any = {
      data: { token },
      method: 'POST',
      mode: 'no-cors',
      url: `${this.baseurl}token/reset`,
      crossdomain: true,
      headers: {
        'Content-Type': 'application/json',
      },
    };
    return await axios(options)
      .then(json => json.data)
      .then(res => res)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  /**
   * Enable 2FA
   *
   * @param   {string} token
   * @returns {object}
   */
  static async enable2FA() {
    const options: any = {
      method: 'POST',
      mode: 'no-cors',
      url: `${this.baseurl}mfa/enable`,
      crossdomain: true,
      headers,
    };
    return await axios(options)
      .then(json => json.data)
      .then(res => res)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }
}
