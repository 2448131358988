/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import PrivateRoute from './private';

import Modal from './components/modal';
import { ICOPage, AccountPage } from './pages/Account/Loadable';
import {
  AuthPage,
  LogoutPage,
  LoginPage,
  ActivateAccountPage,
  VerifyLoginPage,
} from './pages/Auth/Loadable';
import {
  LegalVASPDisclaimerPage,
  LegalTermsPage,
  LegalPrivacyPage,
} from './pages/Legal/Loadable';
import { MaintenancePage } from './pages/Maintenance';
import { NotFoundPage } from './components/NotFoundPage/Loadable';
import { useTranslation } from 'react-i18next';
import { Session } from './services/session.service';
import config from './config';

import './index.scss';

// number in minutes
const autoTimeoutTimeInMinutes = 10;

export function App() {
  const { i18n } = useTranslation();
  const [autoLogout, setAutoLogout] = React.useState<number>(
    autoTimeoutTimeInMinutes,
  );
  const [showTimeoutModal, setShowTimeoutModal] =
    React.useState<boolean>(false);

  async function fetchData() {
    const isAuth = await Session.isValid();
    if (isAuth) {
      setAutoLogout(autoTimeoutTimeInMinutes);
    }
  }

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleOnIdle = () => {
    const pathname = window.location.pathname;
    const rootPage = pathname.substring(1);
    const routes = [
      'login',
      'terms',
      'logout',
      'results',
      'privacy',
      'register',
      'activate',
      'password',
    ];

    if (rootPage.length === 0 || routes.includes(rootPage)) {
      return;
    }
    if (!config.stage.includes('prod') && !config.stage.includes('dev')) {
      return;
    }
    // if ${autoLogout} minutes have elapsed auto-logged
    setShowTimeoutModal(true);
  };

  const handleOnActive = (event: any) => {
    console.log('user is active', event);
    console.log('time remaining', getRemainingTime());
  };

  const handleOnAction = (event: any) => {
    console.log('user did something', event);
  };

  const logout = async () => {
    await Session.destroy();
    setShowTimeoutModal(false);
    window.location.href = '/login?session_timeout=true';
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * autoLogout,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });

  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s - CBI DAO ICO"
        defaultTitle="CBI DAO ICO"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="A CBI DAO ICO application" />
      </Helmet>
      {showTimeoutModal && (
        <Modal.SessionTimeout
          callback={logout}
          show={showTimeoutModal}
          setShow={setShowTimeoutModal}
        />
      )}
      <Switch>
        {/* <Redirect exact path="/" to="/maintenance" />
        <Redirect exact path="/login" to="/maintenance" />
        <Redirect exact path="/register" to="/maintenance" />
        <Redirect exact path="/password/forgot" to="/maintenance" />
        <Redirect exact path="/account" to="/maintenance" />
        <Redirect exact path="/account/ico" to="/maintenance" />
        <Redirect exact path="/auth" to="/maintenance" />
        <Route exact path="/maintenance" component={MaintenancePage} /> */}

        <Redirect exact path="/" to="/login" />
        <Route exact path="/auth" component={AuthPage} />
        <Route exact path="/terms" component={LegalTermsPage} />
        <Route exact path="/privacy" component={LegalPrivacyPage} />
        <Route
          exact
          path="/disclaimer/vasp"
          component={LegalVASPDisclaimerPage}
        />
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/logout" component={LogoutPage} />
        <Route exact path="/activate/:token" component={ActivateAccountPage} />
        <Route exact path="/login/verify/:token" component={VerifyLoginPage} />

        <PrivateRoute exact path="/account" component={AccountPage} />
        <PrivateRoute exact path="/account/ico" component={ICOPage} />

        <Route component={NotFoundPage} />
      </Switch>
    </BrowserRouter>
  );
}
