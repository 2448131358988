import axios from 'axios';
import config from '../config';
import { Session } from './session.service';

const token = Session.getToken();
const headers = {
  Authorization: `Bearer ${token}`,
  'Content-Type': 'application/json',
};

export class CalculationsService {
  /**
   * Base url
   */
  static baseurl = config.services.transaction;

  static async deposit(input_amount: number, currency_code: any) {
    const data: any = {
      input_amount,
      currency_code,
    };
    const options: any = {
      mode: 'no-cors',
      method: 'POST',
      url: `${this.baseurl}calculate/deposit`,
      crossdomain: true,
      headers,
      data,
    };
    return axios(options)
      .then(json => json.data)
      .then(res => {
        const { data } = res;
        if (data) {
          const { amount } = data;
          return amount || 0;
        }
        return 0;
      })
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  static async withdraw(
    input_amount: number,
    currency_code: any,
    specification: any = null,
  ) {
    const data: any = {
      input_amount,
      currency_code,
      specification,
    };
    const options: any = {
      mode: 'no-cors',
      method: 'POST',
      url: `${this.baseurl}calculate/withdraw`,
      crossdomain: true,
      headers,
      data,
    };
    return axios(options)
      .then(json => json.data)
      .then(res => {
        const { data } = res;
        if (data) {
          const { amount } = data;
          return amount || {};
        }
        return {};
      })
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }
}
