import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CalculationsService } from '../../services/calculations.service';
import { CurrencyService } from '../../services/currency.service';
import { TransactionService } from '../../services/transaction.service';
import { UserService } from '../../services/user.service';
import { _mask } from '../../../utils/fn';
import Loader from '../common/loader';
import Swal from 'sweetalert2';

const BankDepositModal = props => {
  const { show, setShow, currencyCode, amount, profile } = props;
  const [agree, setAgree] = useState<boolean>(false);
  const [wallet, setWallet] = useState<any>();
  const [currency, setCurrency] = useState<any>(null);
  const [referenceNumber] = useState<any>(profile.email);
  const [depositAccountNumber] = useState<any>('200054881006');
  const [amountInCBI, setAmountInCBI] = useState<any>(0.0);
  const [componentLoading, setComponentLoading] = useState<boolean>(true);
  const [showThankYou, setShowThankYou] = useState<boolean>(false);
  const history = useHistory();

  async function fetchData() {
    const wallet: any = await UserService.wallet();
    const currency = await CurrencyService.get(currencyCode);
    const amountInCBI = await CalculationsService.deposit(amount, currencyCode);
    setAmountInCBI(amountInCBI);
    setCurrency(currency);
    setWallet(wallet);
    setComponentLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  const toggle = () => setShow(!show);

  const close = () => history.push('/account/transactions');

  const hanndleCopyAccountNumber = () => {
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Number copied to clipboard',
      showConfirmButton: false,
      timer: 2000,
    });
  };

  const hanndleReference = () => {
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Reference copied to clipboard',
      showConfirmButton: false,
      timer: 2000,
    });
  };

  const handleDepositMade = async () => {
    setComponentLoading(true);
    const data: any = {
      currency_code: currencyCode,
      account_id: wallet.id,
      tx_type: 'credit',
      subtype: 'deposit',
      type: 'bank',
      amount,
    };
    const response: any = await TransactionService.create(data);
    const { success, message } = response;

    setComponentLoading(false);

    if (success) {
      return setShowThankYou(true);
    }

    toggle();

    Swal.fire({
      position: 'center',
      icon: 'error',
      title: message || 'Failed to process request, please try again!',
      showConfirmButton: false,
      timer: 4000,
    });
  };

  return (
    <div>
      <Modal isOpen={show} toggle={toggle} size="md" backdrop="static">
        {componentLoading && <Loader type="card" />}
        <ModalHeader toggle={toggle}>
          {showThankYou ? (
            <>Awesome, we'll await bank confirmation</>
          ) : (
            <>{agree ? 'Make your transfer' : 'Before you buy, please note:'}</>
          )}
        </ModalHeader>
        <ModalBody className="pt-0">
          {showThankYou ? (
            <div className="mt-3">
              <p>This usually takes up to 3 business days to reflect.</p>
              <p>
                As soon as the bank transfer reflects,&nbsp;
                <strong>we will let you know</strong>
                &nbsp;and add it straight to your crypto wallet.
              </p>
              <p>
                To speed up the process you can email your proof of payment
                to&nbsp;
                <a href="mailto:pop@cbi-x.io" className="text-primary">
                  pop@cbi-x.io
                </a>
                .
              </p>
              <div className="d-block text-center mb-1 mt-3">
                <button
                  type="button"
                  className="btn btn-primary px-4 me-1"
                  onClick={close}
                >
                  GOT IT
                </button>
              </div>
            </div>
          ) : (
            <>
              {agree ? (
                <>
                  <div className="buyer-seller">
                    <small className="mt-3 mb-2 d-block">
                      Sign in to your banking app or online banking account,
                      create a recipient and make your transfer using the
                      information below:
                    </small>
                    <div className="table-responsive">
                      <table className="table mb-0">
                        <tbody>
                          <tr>
                            <td className="deposit-details">
                              <div className="row">
                                <small>HOLDER NAME</small>
                                <label>CBI Exchange</label>
                              </div>
                              <div className="row">
                                <small>BANK NAME</small>
                                <label>Standard Bank</label>
                              </div>
                              <div className="row">
                                <small>ACCOUNT NUMBER</small>
                                <label>
                                  {depositAccountNumber}
                                  &nbsp;&nbsp;
                                  <CopyToClipboard
                                    text={depositAccountNumber}
                                    onCopy={hanndleCopyAccountNumber}
                                    className="ml-3 pointer-on-hover"
                                  >
                                    <span>
                                      <i className="fa fa-copy" />
                                    </span>
                                  </CopyToClipboard>
                                </label>
                              </div>
                              <div className="row">
                                <small>REFERENCE</small>
                                <label>
                                  {referenceNumber}
                                  &nbsp;&nbsp;
                                  <CopyToClipboard
                                    text={referenceNumber}
                                    onCopy={hanndleReference}
                                    className="ml-3 pointer-on-hover"
                                  >
                                    <span>
                                      <i className="fa fa-copy" />
                                    </span>
                                  </CopyToClipboard>
                                </label>
                              </div>
                              <div className="row">
                                <small>AMOUNT TO TRANSFER</small>
                                <label>
                                  {amount} {currency.code}
                                </label>
                              </div>
                              <div className="row">
                                <small>ESTIMATED AMOUNT YOU WILL RECEIVE</small>
                                <label>{amountInCBI} CBI</label>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <ul className="unordered-list mb-3">
                    <li>
                      <small>
                        Note: 1 Namibian Dollar (NAD) = 1 South African Rand
                        (ZAR)
                      </small>
                    </li>
                    <li>
                      <small>
                        Copy the above account number to make a transfer.
                      </small>
                    </li>
                    <li>
                      <small>
                        Only use <strong>{referenceNumber}</strong> as reference
                      </small>
                    </li>
                    <li>
                      <small>
                        By continuing with this transaction you agree to&nbsp;
                        <a
                          href="/terms"
                          className="text-primary"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          CBI-X Terms and Conditions
                        </a>
                      </small>
                    </li>
                  </ul>
                  <div className="d-block text-center mb-2 mt-2">
                    <button
                      type="button"
                      className="btn btn-primary bg-gradient-dark px-5 me-1"
                      onClick={handleDepositMade}
                    >
                      I HAVE ALREADY MADE A DEPOSIT
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <ul className="unordered-list mb-3 mt-3">
                    <li>
                      <small>
                        This usually takes up to 3 business days to reflect.
                      </small>
                    </li>
                    <li>
                      <small>
                        Transfer only to the <strong>Standard Bank</strong>{' '}
                        account details above.
                      </small>
                    </li>
                    <li>
                      <small>
                        Only use <strong>{referenceNumber}</strong> as reference
                      </small>
                    </li>
                    <li>
                      <small>
                        By continuing with this transaction you agree to&nbsp;
                        <a
                          href="/terms"
                          className="text-primary"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          CBI-X Terms and Conditions
                        </a>
                      </small>
                    </li>
                    <li>
                      <small>
                        To speed up process, email proof of payment
                        <br />
                        to&nbsp;
                        <a
                          href="mailto:pop@cbi-x.io"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-primary"
                        >
                          pop@cbi-x.io
                        </a>
                      </small>
                    </li>
                  </ul>
                  <div className="mb-3 mb-0">
                    <label className="toggle">
                      <input
                        type="checkbox"
                        className="toggle-checkbox"
                        onChange={(e: any) => setAgree(e.target.checked)}
                      />
                      <div className="toggle-switch" />
                      <span className="toggle-label">
                        I understand and accept
                      </span>
                    </label>
                  </div>
                </>
              )}
            </>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default BankDepositModal;
