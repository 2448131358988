import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { GenericService } from '../../services/generic.service';
import { UserService } from '../../services/user.service';
import { useForm } from 'react-hook-form';
import Loader from '../common/loader';
import validator from 'validator';
import InputError from '../../components/form/error';
import validationMessages from '../../../static/validation-messages.json';

const errorMessages = validationMessages.crypto_account;

const AddCryptoAddressModal = props => {
  const {
    setError,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const {
    show,
    setShow,
    pageLoading,
    setPostData,
    setTransaction,
    setShowOtpVerify,
  } = props;
  const [componentLoading, setComponentLoading] = useState<boolean>(true);
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(true);
  const [selectedCurrency, setSelectedCurrency] = useState<any>({});
  const [currencies, setCurrencies] = useState<any>([]);
  const [agree, setAgree] = useState<boolean>(false);
  const [networks, setNetworks] = useState<any>([]);
  const [currency, setCurrency] = useState<any>();

  async function fetchData() {
    const currencies = await GenericService.get('currencies?type=crypto');
    if (currencies.results) {
      setSelectedCurrency(currencies.results[0]);
      setNetworks(currencies.results[0].currency_networks);
      setCurrency(currencies.results[0].code);
      setCurrencies(currencies.results);
    }
    setTransaction('crypto-account.add');
    setComponentLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  const toggle = () => setShow(!show);

  async function onSubmit(data) {
    const { address, currency_code } = data;
    data.code = currency_code;

    // validate
    setComponentLoading(true);
    const response = await UserService.validateCryptoAccount({
      currency_code,
      address,
    });

    setComponentLoading(false);
    const { valid } = response;

    if (valid) {
      toggle();
      setPostData(data);
      return setShowOtpVerify(true);
    }

    return setError('address', {
      type: 'custom',
      message: 'The provided wallet address does not match the Polygon network. Please provide a non-custodial wallet address from Polygon network.',
    });
  }

  const handleChangeCurrency = (e: any) => {
    setCurrency(e.target.value);
    const [filtered] = currencies.filter(
      (currency: any) => e.target.value === currency.code,
    );
    setNetworks(filtered.currency_networks);
    setSelectedCurrency(filtered);
  };

  return (
    <div>
      <Modal isOpen={show} toggle={toggle} size="md" backdrop="static">
        {componentLoading && <Loader type="card" />}
        {showDisclaimer ? (
          <ModalBody className="text-center">
            <img
              src="/images/icons/warning.png"
              className="icon my-3"
              alt="Warning"
            />
            <p className="mx-3">
              For security purposes, <strong>no withdrawals</strong>&nbsp; will
              be allowed for <strong>24 hours</strong> after changes to any bank
              account details or crypto address details.
            </p>
            <p>
              By proceeding you agree to&nbsp;
              <a
                href="/terms"
                className="text-primary"
                target="_blank"
                rel="noopener noreferrer"
              >
                CBI-X Terms and Conditions
              </a>
            </p>
            <button
              type="button"
              className="btn btn-success w-100 bg-gradient-dark px-4"
              onClick={() => setShowDisclaimer(false)}
            >
              Got it, proceed
            </button>
            <div className="mt-3 mb-1 text-center">
              <a
                href=" "
                className="text-muted"
                onClick={(e: any) => {
                  e.preventDefault();
                  return toggle();
                }}
              >
                Cancel
              </a>
            </div>
          </ModalBody>
        ) : (
          <>
            <ModalHeader toggle={toggle}>Add New Crypto Account</ModalHeader>
            <ModalBody className="pt-0">
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="signin_validate"
              >
                <div className="mb-3 mt-3">
                  <label className="me-sm-2 mb-2">Choose crypto currency</label>
                  {pageLoading ? (
                    <div>Loading...</div>
                  ) : (
                    <>
                      <select
                        className={`form-control ${
                          errors.currency_code ? 'is-invalid' : ''
                        }`}
                        defaultValue={currency}
                        {...register('currency_code', {
                          required: true,
                        })}
                        onChange={handleChangeCurrency}
                      >
                        {currencies.map((currency: any) => (
                          <option key={currency.code} value={currency.code}>
                            {currency.code} - {currency.description} (
                            {currency.symbol})
                          </option>
                        ))}
                      </select>
                      <InputError
                        name="currency_code"
                        errors={errors}
                        messages={errorMessages.currency_code}
                      />
                    </>
                  )}
                </div>
                {currency !== 'CBI' && (
                  <div className="mb-3">
                    <label className="me-sm-2 mb-2">
                      Choose from the supported networks below
                    </label>
                    <select className="form-control">
                      {networks.map((item: any) => (
                        <option value={item.id}>
                          {item.network.description} ({item.network.code})
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                <div className="mb-3">
                  <label className="me-sm-2 mb-2">
                    {currency === 'CBI' ? (
                      <span>Enter your CBI username</span>
                    ) : (
                      <span>Give your account a name for display purposes</span>
                    )}
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.name ? 'is-invalid' : ''
                    }`}
                    placeholder={`E.g. ${currency} ${
                      currency === 'CBI' ? 'username' : 'address'
                    }`}
                    {...register('name', {
                      required: true,
                      validate: value =>
                        validator.isAlphanumeric(value, 'en-US', {
                          ignore: ' ',
                        }),
                    })}
                  />
                  <InputError
                    name="name"
                    errors={errors}
                    messages={errorMessages.name}
                  />
                  {currency !== 'CBI' && (
                    <span
                      className="d-block mt-1"
                      style={{ lineHeight: '17px' }}
                    >
                      <small className="hint-text" style={{ lineHeight: 1 }}>
                        NB: Address name/label is a customized name you can give
                        to each off-ramping address for your own reference.
                      </small>
                    </span>
                  )}
                </div>
                <div className="mb-4">
                  <label className="me-sm-2 mb-2">
                    Enter your{' '}
                    {currency === 'CBI' ? 'user code' : 'crypto address'}
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.address ? 'is-invalid' : ''
                    }`}
                    placeholder={`Copy and paste ${
                      currency === 'CBI' ? 'user code' : 'address'
                    } here`}
                    {...register('address', {
                      required: true,
                    })}
                  />
                  <InputError
                    name="address"
                    errors={errors}
                    messages={errorMessages.address}
                  />
                </div>
                <div className="mb-3 mb-0">
                  <label className="toggle">
                    <input
                      type="checkbox"
                      className="toggle-checkbox"
                      onChange={(e: any) => setAgree(e.target.checked)}
                    />
                    <div className="toggle-switch" />
                    <span className="toggle-label">
                      By submitting you agree to&nbsp;
                      <a
                        href="/terms"
                        className="text-primary"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        CBI-X Terms and Conditions
                      </a>
                    </span>
                  </label>
                </div>
                <div className="text-center mb-3">
                  <button
                    type="submit"
                    className="btn btn-success bg-gradient-dark w-100"
                    disabled={!agree}
                  >
                    Add New Addess
                  </button>
                </div>
              </form>
            </ModalBody>
          </>
        )}
      </Modal>
    </div>
  );
};

export default AddCryptoAddressModal;
