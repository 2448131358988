import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

const WithdrawalDisclaimerModal = (props: any) => {
  const { show, setShow, callback, description } = props;

  const toggle = () => setShow(!show);

  const handleContinue = () => {
    callback();
  };

  return (
    <div>
      <Modal isOpen={show} toggle={toggle} size="md" backdrop="static">
        <ModalBody className="text-center">
          <img
            src="/images/icons/warning.png"
            className="icon my-3"
            alt="Warning"
          />
          <p className="mx-3">
            For security purposes, <strong>no withdrawals</strong>&nbsp; will be
            allowed for <strong>24 hours</strong> after changes to {description}
            .
          </p>
          <p>
            By proceeding you agree to&nbsp;
            <a
              href="/terms"
              className="text-primary"
              target="_blank"
              rel="noopener noreferrer"
            >
              CBI-X Terms and Conditions
            </a>
          </p>
          <button
            type="button"
            className="btn btn-success w-100 bg-gradient-dark px-4"
            onClick={handleContinue}
          >
            Got it, proceed
          </button>
          <div className="mt-3 mb-1 text-center">
            <a
              href=" "
              className="text-muted"
              onClick={(e: any) => {
                e.preventDefault();
                return toggle();
              }}
            >
              Cancel
            </a>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default WithdrawalDisclaimerModal;
