import React from 'react';
import CurrencyFormat from 'react-currency-format';

const FormatNumber = (props: any) => {
  const { amount, decimalScale, currencyCode } = props;

  return (
    <CurrencyFormat
      thousandSeparator=" "
      displayType="text"
      value={amount || 0}
      fixedDecimalScale
      decimalScale={decimalScale || 4}
      renderText={value => (
        <span>
          {value} {currencyCode || ''}
        </span>
      )}
    />
  );
};

export default FormatNumber;
