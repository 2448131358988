import AddBankAccount from './add-bank-account';
import AddBuddyAccount from './add-buddy-account';
import AddCryptoAddress from './add-crypto-address';
import AddTokenAddress from './add-token-wallet-address';
import ConfirmBuyTokens from './confirm-buy-cbid-tokens';
import UpdateBankAccount from './update-bank-account';
import UpdateCryptoAddress from './update-crypto-address';
import UpdateTokenAddress from './update-token-wallet-address';
import Default from './default';
import DepositCrypto from './deposit-crypto';
import DepositBank from './deposit-bank';
import DepositDafriBank from './deposit-dafribank';
import Information from './information';
import KYCDetail from './kyc-detail';
import KYCLevelOne from './kyc-level-one';
import KYCLevelTwo from './kyc-level-two';
import OTPVerify from './otp-verify';
import SessionTimeout from './session-timeout';
import WithdrawCrypto from './withdraw-crypto';
import WithdrawBank from './withdraw-bank';
import WithdrawBuddy from './withdraw-buddy';
import WithdrawalDisclaimer from './withdrawal-disclaimer';

export default {
  AddBankAccount,
  AddBuddyAccount,
  AddCryptoAddress,
  AddTokenAddress,
  ConfirmBuyTokens,
  UpdateBankAccount,
  UpdateCryptoAddress,
  UpdateTokenAddress,
  Default,
  DepositCrypto,
  DepositBank,
  DepositDafriBank,
  Information,
  KYCDetail,
  KYCLevelOne,
  KYCLevelTwo,
  OTPVerify,
  SessionTimeout,
  WithdrawCrypto,
  WithdrawBank,
  WithdrawBuddy,
  WithdrawalDisclaimer,
};
