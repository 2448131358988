import React from 'react';
import { Dropzone } from '../../media';
import ActionButtons from './action-buttons';

const StepTwo = (props: any) => {
  const { error, setError, setDocument } = props;

  const handleLastStep = () => {
    props.lastStep();
    props.completeCallback();
  };

  return (
    <div>
      <h2>Proof of Residence</h2>
      <h4 className="mt-4">Upload your proof of residence</h4>
      {error && <div className="alert alert-danger">{error}</div>}
      <Dropzone
        filename="confirmation_letter"
        label="Upload or drop your document here"
        setFile={(file: any) => {
          setDocument(file);
          setError(null);
        }}
        onSizeError={(file: any) =>
          setError(
            'File maximum size (5MB) exceeded. Please upload a smaller file in size.',
          )
        }
      />
      <h4>Ensure that the document meet all the standard below:</h4>
      <ul>
        <li>Size does not exceed 5MB</li>
        <li>Readable, clear and well-lit</li>
        <li>Not reflective, not blurry</li>
        <li>Supported file formats/types: JPG, PNG, PDF, JPEG</li>
      </ul>
      <br />
      <ActionButtons
        {...props}
        error={error}
        setError={setError}
        lastStep={handleLastStep}
        disabled={error ? true : false}
      />
    </div>
  );
};

export default StepTwo;
