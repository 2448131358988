import axios from 'axios';
import config from '../config';
import { Session } from './session.service';

export class GenericService {
  /**
   * Base url
   */
  static baseurl = config.services.generic;

  /**
   * Get endpoint
   * @param uri
   * @returns
   */
  static async get(uri: any, is_auth: boolean = false) {
    const headers: any = {
      'Content-Type': 'application/json',
    };

    // if this is an authenticated call
    if (is_auth) {
      const token = Session.getToken();
      headers.Authorization = `Bearer ${token}`;
    }

    const options: any = {
      method: 'GET',
      mode: 'no-cors',
      url: this.baseurl + uri,
      crossdomain: true,
      headers,
    };
    return await axios(options)
      .then(json => json.data)
      .then(res => res.data || {})
      .catch(err => err);
  }
}
