import React from 'react';
import ActionButtons from './action-buttons';

const StepTwo = (props: any) => {
  const { identityType } = props;
  return (
    <div>
      <h2>Identity Verification</h2>
      <div className="mx-5 my-5">
        <div className="id_card mx-5 my-5">
          <img src="/images/settings/id.png" className="img-fluid" alt="KYC" />
        </div>
      </div>
      <h4 className="mt-4">
        You are about to upload your {identityType}. Please ensure that:
      </h4>
      <ul className="mb-4">
        <li>This is your own government-issued document that is not expired</li>
        <li>This is an original document, not a scan or copy</li>
        <li>Avoid reflections or blur</li>
      </ul>
      <ActionButtons {...props} />
    </div>
  );
};

export default StepTwo;
