import React, { useEffect, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';

const SessionTimeoutModal = (props: any) => {
  const { show, setShow, callback } = props;
  const [countdown, setCountdown] = useState<number>(15);
  const [timeoutInterval, setTimeoutInterval] = useState<any>();

  useEffect(() => {
    let sec = 15;
    const interval = setInterval(() => {
      sec--;
      if (sec === 0) {
        return callback();
      }
      setCountdown(sec);
    }, 1000);

    setTimeoutInterval(interval);
  }, []);

  const toggle = () => setShow(!show);

  const handleContinue = () => {
    clearInterval(timeoutInterval);
    setShow(false);
  };

  return (
    <div>
      <Modal isOpen={show} toggle={toggle} size="md" backdrop="static">
        <ModalBody className="text-center">
          <img
            src="/images/icons/warning.png"
            className="icon my-3"
            alt="Warning"
          />
          <h3>
            <strong>Session will expire soon</strong>
          </h3>
          <p className="mx-3">
            For security purposes, you will be automatically logged out in:
          </p>
          <h1>
            <strong>00:{countdown < 10 ? `0${countdown}` : countdown}</strong>
          </h1>
          <p>Would you like to keep working?</p>
          <button
            type="button"
            className="btn btn-success w-100 bg-gradient-dark px-4"
            onClick={handleContinue}
          >
            Keep Working
          </button>
          <div className="mt-3 mb-1 text-center">
            <a
              href=" "
              className="text-muted"
              onClick={(e: any) => {
                e.preventDefault();
                return callback();
              }}
            >
              Log out
            </a>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default SessionTimeoutModal;
