import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

const InformationModal = (props: any) => {
  const { icon, show, setShow, onClose, btnText, preventDefault } = props;

  const toggle = () => {
    if (!preventDefault) {
      setShow(!show);
    }
    if (onClose && typeof onClose === 'function') {
      onClose();
    }
  };

  return (
    <div>
      <Modal isOpen={show} toggle={toggle} size="md" backdrop="static">
        <ModalBody className="text-center">
          <img
            src={`/images/icons/${icon || '004-information.png'}`}
            className="icon my-3"
            alt="Warning"
          />
          <div className="mb-3">{props.children}</div>
          <button
            type="button"
            className="btn btn-success w-100 bg-gradient-dark px-4"
            onClick={toggle}
          >
            {btnText || 'Got it'}
          </button>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default InformationModal;
