import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

const DefaultModal = (props: any) => {
  const {
    size,
    show,
    setShow,
    onClose,
    modalTitle,
    preventDefault,
    hideHeader,
    backdrop,
  } = props;

  const toggle = () => {
    if (!preventDefault) {
      setShow(!show);
    }
    if (onClose && typeof onClose === 'function') {
      onClose();
    }
  };

  return (
    <div>
      <Modal
        isOpen={show}
        toggle={toggle}
        size={size || 'md'}
        backdrop={backdrop || 'static'}
        className="popup-modal"
      >
        {!hideHeader && <ModalHeader toggle={toggle}>{modalTitle || ''}</ModalHeader>}
        <ModalBody>{props.children}</ModalBody>
      </Modal>
    </div>
  );
};

export default DefaultModal;
