import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { GenericService } from '../../services/generic.service';
import validator from 'validator';
import InputError from '../../components/form/error';
import validationMessages from '../../../static/validation-messages.json';

const errorMessages = validationMessages['buddy_account'];

const AddBuddyAccountModal = (props: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const {
    show,
    setShow,
    pageLoading,
    setPageLoading,
    setPostData,
    setShowOtpVerify,
    setTransaction,
  } = props;
  const [phoneCode, setPhoneCode] = useState<any>();
  const [agree, setAgree] = useState<boolean>(false);
  const [countries, setCountries] = useState<any>([]);
  const [selectedCountry, setSelectedCountry] = useState<any>({});
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(true);

  async function fetchData() {
    if (!pageLoading) setPageLoading(true);
    const countries = await GenericService.get('countries');
    const permitted = await GenericService.get(
      'settings/application/buddy.permitted.countries',
      true,
    );
    if (countries.results && permitted) {
      const filtered: any = countries.results.filter((item: any) => {
        const permittedCountries = JSON.parse(permitted.value);
        return permittedCountries.includes(item.iso);
      });
      setPhoneCode(filtered[0].phone_code);
      setSelectedCountry(filtered[0]);
      setCountries(filtered);
    }
    setTransaction('buddy-account.add');
    setPageLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  const toggle = () => setShow(!show);

  async function onSubmit(data) {
    toggle();
    let number: any = data.identifier.trim().replace('+', '');

    if (number.indexOf(phoneCode) === 0) {
      number = number;
    } else if (
      (phoneCode.toString() === '27' || phoneCode.toString() === '264') &&
      number.indexOf('0') === 0
    ) {
      number = phoneCode + number.substring(1);
    } else {
      number = phoneCode + number;
    }

    setPostData({ ...data, identifier: number });
    setShowOtpVerify(true);
  }

  const handleChangeCountry = (e: any) => {
    const [filtered] = countries.filter(
      (item: any) => item.iso === e.target.value,
    );
    setPhoneCode(filtered.phone_code);
    setSelectedCountry(filtered);
  };

  return (
    <div>
      <Modal isOpen={show} toggle={toggle} size="md" backdrop="static">
        {showDisclaimer ? (
          <ModalBody className="text-center">
            <img
              src="/images/icons/warning.png"
              className="icon my-3"
              alt="Warning"
            />
            <p className="mx-3">
              For security purposes, <strong>no withdrawals</strong>&nbsp; will
              be allowed for <strong>24 hours</strong> after changes to any bank
              account details or crypto address details.
            </p>
            <p>
              By proceeding you agree to&nbsp;
              <a
                href="/terms"
                className="text-primary"
                target="_blank"
                rel="noopener noreferrer"
              >
                CBI-X Terms and Conditions
              </a>
            </p>
            <button
              type="button"
              className="btn btn-success w-100 bg-gradient-dark px-4"
              onClick={() => setShowDisclaimer(false)}
            >
              Got it, proceed
            </button>
            <div className="mt-3 mb-1 text-center">
              <a
                href=" "
                className="text-muted"
                onClick={(e: any) => {
                  e.preventDefault();
                  return toggle();
                }}
              >
                Cancel
              </a>
            </div>
          </ModalBody>
        ) : (
          <>
            <ModalHeader toggle={toggle}>Link Buddy Account</ModalHeader>
            <ModalBody className="pt-0">
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="signin_validate"
              >
                <div className="mb-3 mt-3">
                  <label className="me-sm-2 mb-2">
                    Give your account a name for display purposes
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.label ? 'is-invalid' : ''
                    }`}
                    placeholder="Buddy account name/label"
                    {...register('label', {
                      required: true,
                      minLength: 3,
                      validate: value =>
                        validator.isAlpha(value, 'en-US', { ignore: ' ' }),
                    })}
                  />
                  <InputError
                    name="label"
                    errors={errors}
                    messages={errorMessages.label}
                  />
                </div>
                <div className="mb-3">
                  <label className="me-sm-2 mb-2">Buddy identifier</label>
                  {selectedCountry.iso && (
                    <div className="input-group">
                      <select
                        className="form-control"
                        defaultValue={selectedCountry.iso}
                        onChange={handleChangeCountry}
                      >
                        {countries.map((country: any) => (
                          <option key={country.id} value={country.iso}>
                            {country.nicename} (+{country.phone_code})
                          </option>
                        ))}
                      </select>
                      <label className="input-group-text phone-code">
                        +{phoneCode}
                      </label>
                      <input
                        type="text"
                        maxLength={13}
                        className={`form-control ${
                          errors.mobile ? 'is-invalid' : ''
                        }`}
                        placeholder="E.g. 123456789"
                        {...register('identifier', {
                          required: true,
                          minLength: 6,
                          maxLength: 13,
                          validate: value => validator.isNumeric(value),
                        })}
                      />
                    </div>
                  )}
                  {errors.identifier ? (
                    <div>
                      <InputError
                        name="identifier"
                        errors={errors}
                        messages={errorMessages.identifier}
                      />
                    </div>
                  ) : (
                    <div className="d-flex text-muted justify-content-between mb-1">
                      <small className="mb-0">
                        Hint: Select country to populate phone code, and enter
                        Buddy identifier excl. country code.
                      </small>
                    </div>
                  )}
                </div>
                <div className="mb-3 mb-0">
                  <label className="toggle">
                    <input
                      type="checkbox"
                      className="toggle-checkbox"
                      onChange={(e: any) => setAgree(e.target.checked)}
                    />
                    <div className="toggle-switch" />
                    <span className="toggle-label">
                      By submitting you agree to&nbsp;
                      <a
                        href="/terms"
                        className="text-primary"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        CBI-X Terms and Conditions
                      </a>
                    </span>
                  </label>
                </div>
                <div className="text-center mb-3">
                  <button
                    type="submit"
                    className="btn btn-success bg-gradient-dark w-100"
                    disabled={!agree}
                  >
                    Save Account
                  </button>
                </div>
              </form>
            </ModalBody>
          </>
        )}
      </Modal>
    </div>
  );
};

export default AddBuddyAccountModal;
