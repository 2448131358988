import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { AuthService } from '../../services/auth.service';
import { GeoLocationService } from '../../services/geolocation.service';
import { TransactionService } from '../../services/transaction.service';
import { UserService } from '../../services/user.service';
import { _mask } from '../../../utils/fn';
import ReactCodeInput from 'react-verification-code-input';
import CONSTANTS from '../../../static/constants.json';
import Loader from '../common/loader';
import Swal from 'sweetalert2';

const OTP_ACTIONS = CONSTANTS['OTP_ACTIONS'];

const numOfFields: any = 6;
const countdownTimer: any = 60; // in seconds

const OTPVerifyModal = (props: any) => {
  const {
    show,
    postId,
    setShow,
    postData,
    transaction,
    callback,
  } = props;

  const [caption, setCaption] = useState<any>();
  const [numOfInputedFields, setNumOfInputedFields] = useState<number>(0);
  const [componentLoading, setComponentLoading] = useState<boolean>(true);
  const [countdown, setCountdown] = useState<number>(countdownTimer);
  const [allowResend, setAllowResend] = useState<boolean>(false);
  const [isTwoFactor, setIsTwoFactor] = useState<boolean>(
    postData.verify_2fa || false,
  );
  const [attempts, setAttempts] = useState<any>(0);
  const [action, setAction] = useState<any>({});
  const [profile, setProfile] = useState<any>();
  const [error, setError] = useState<any>();
  const history = useHistory();

  const getCaption = (twofactor: boolean = false, data: any = {}) => {
    if (twofactor) {
      return (
        <p>
          Please enter the {numOfFields} digit 2FA code generated by your
          Authenticator App{' '}
          {data.twofactor && data.twofactor.mobile && (
            <span>
              associated with{' '}
              {transaction === 'ico-token-confirm.buy'
                ? ' your CBI-X account: '
                : ''}{' '}
              {_mask(data.twofactor.mobile, 4, 3)}
            </span>
          )}
          .
        </p>
      );
    }

    const { mobile } = data;
    const musk = _mask(mobile, 4, 3);
    return (
      <p>
        Please enter the {numOfFields} digit code sent to +{musk}. If not
        received within a minute please try resend option below.
      </p>
    );
  };

  async function fetchData() {
    if (postData.verify_2fa) {
      setCaption(getCaption(true));
      return setComponentLoading(false);
    }

    const response: any = await AuthService.sendOTP({ transaction });
    const { success, message, twofactor } = response;
    if (success) {
      const profile: any = await UserService.profile();

      if (twofactor) {
        setProfile(profile);
        setIsTwoFactor(twofactor);
        setCaption(getCaption(twofactor, profile.mfa));
        return setComponentLoading(false);
      } else {
        setIsTwoFactor(false);
        const geoinfo: any = await GeoLocationService.get();
        postData.geoinfo = geoinfo;
        const { mobile } = profile;
        if (mobile) {
          setCaption(getCaption(twofactor, { mobile }));
          setProfile(profile);
          return setComponentLoading(false);
        }
      }
    }

    if (message.toLowerCase().includes('failed to authenticate token')) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Session Expired!',
        text: 'Unfortunately, your session has expired. Please login and try again.',
        showConfirmButton: false,
        timer: 6000,
      });
      return setTimeout(async function () {
        window.location.href = '/logout';
      }, 6000);
    }

    Swal.fire({
      position: 'center',
      icon: 'error',
      title: 'Failed to process request, please try again!',
      showConfirmButton: false,
      timer: 4000,
    });
  }

  let counter = 60;
  let timeout;
  let timer_on = 0;

  function timedCount() {
    if (counter <= 0) return stopCount();
    setCountdown(counter);
    counter--;
    timeout = setTimeout(timedCount, 1000);
  }

  function startCount() {
    if (!timer_on) {
      timer_on = 1;
      timedCount();
    }
  }

  function stopCount() {
    clearTimeout(timeout);
    timer_on = 0;
    setAllowResend(true);
  }

  function doResendCountdown() {
    setAllowResend(false);
    startCount();
  }

  useEffect(() => {
    fetchData();
    doResendCountdown();
    setAction(OTP_ACTIONS[transaction] || {});
  }, []);

  const toggle = () => setShow(!show);

  async function handleAddBankAccount() {
    postData.status = 'Active';
    const response: any = await UserService.createBankAccount(postData);
    const { success, message } = response;

    setComponentLoading(false);
    toggle();

    if (success) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Request processed successfully!',
        showConfirmButton: false,
        timer: 6000,
      });
      return setTimeout(async function () {
        if (callback && typeof callback === 'function') {
          return callback('Bank');
        }
        history.push('/account/settings');
      }, 6000);
    }
    return Swal.fire({
      position: 'center',
      icon: 'error',
      title: message || 'Failed to process request, please try again!',
      showConfirmButton: false,
      timer: 6000,
    });
  }

  async function handleAddBuddyAccount() {
    const response: any = await UserService.createBuddyAccount(postData);
    const { success, message } = response;

    setComponentLoading(false);
    toggle();

    if (success) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Request processed successfully!',
        showConfirmButton: false,
        timer: 6000,
      });
      return setTimeout(async function () {
        if (callback && typeof callback === 'function') {
          return callback('Buddy');
        }
        history.push('/account/settings');
      }, 6000);
    }
    return Swal.fire({
      position: 'center',
      icon: 'error',
      title: message || 'Failed to process request, please try again!',
      showConfirmButton: false,
      timer: 6000,
    });
  }

  async function handleUpdateBankAccount() {
    delete postData.authenticate;
    postData.status = 'Active';
    const response: any = await UserService.updateBankAccount(postId, postData);
    const { success, message } = response;

    setComponentLoading(false);
    toggle();

    if (success) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Request processed successfully!',
        showConfirmButton: false,
        timer: 6000,
      });
      return setTimeout(async function () {
        if (callback && typeof callback === 'function') {
          return callback('Bank');
        }
        history.push('/account/settings');
      }, 6000);
    }
    return Swal.fire({
      position: 'center',
      icon: 'error',
      title: message || 'Failed to process request, please try again!',
      showConfirmButton: false,
      timer: 6000,
    });
  }

  async function handleMobileNumberVerify() {
    delete postData.authenticate;
    const response: any = await UserService.verifyMobileNumber(postData);
    const { success, message } = response;

    setComponentLoading(false);
    toggle();

    if (success) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Mobile number verified successfully!',
        showConfirmButton: false,
        timer: 6000,
      });
      return setTimeout(async function () {
        if (callback && typeof callback === 'function') {
          return callback();
        }
        history.push('/account/settings');
      }, 6000);
    }
    return Swal.fire({
      position: 'center',
      icon: 'error',
      title: message || 'Failed to process request, please try again!',
      showConfirmButton: false,
      timer: 6000,
    });
  }

  async function handleMobileNumberUpdate() {
    delete postData.authenticate;
    const response: any = await UserService.updateMobileNumber(
      postId,
      postData,
    );
    const { success, message } = response;

    setComponentLoading(false);
    toggle();

    if (success) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Mobile number changed successfully!',
        showConfirmButton: false,
        timer: 6000,
      });
      return setTimeout(async function () {
        if (callback && typeof callback === 'function') {
          return callback();
        }
        history.push('/account/settings');
      }, 6000);
    }
    return Swal.fire({
      position: 'center',
      icon: 'error',
      title: message || 'Failed to process request, please try again!',
      showConfirmButton: false,
      timer: 6000,
    });
  }

  async function handleAddCryptoAddress() {
    delete postData.authenticate;
    const response: any = await UserService.createCryptoAccount(postData);
    const { success, message } = response;

    setComponentLoading(false);
    toggle();

    if (success) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Crypto address captured successfully!',
        showConfirmButton: false,
        timer: 6000,
      });
      return setTimeout(async function () {
        if (callback && typeof callback === 'function') {
          return callback('Crypto');
        }
        history.push('/account/settings');
      }, 6000);
    }
    Swal.fire({
      position: 'center',
      icon: 'error',
      title: message || 'Failed to process request, please try again!',
      showConfirmButton: false,
      timer: 6000,
    });
  }

  async function handleAddTokenAddress() {
    delete postData.authenticate;
    const response: any = await UserService.createCryptoAccount(postData);
    const { success, message } = response;

    setComponentLoading(false);
    toggle();

    if (success) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Token wallet address captured successfully!',
        showConfirmButton: false,
        timer: 6000,
      });
      return setTimeout(async function () {
        if (callback && typeof callback === 'function') {
          return callback();
        }
        history.push('/account/ico');
      }, 6000);
    }
    Swal.fire({
      position: 'center',
      icon: 'error',
      title: message || 'Failed to process request, please try again!',
      showConfirmButton: false,
      timer: 6000,
    });
  }

  async function handleUpdateTokenAddress() {
    delete postData.authenticate;
    const response: any = await UserService.updateCryptoAccount(postId, postData);
    const { success, message } = response;

    setComponentLoading(false);
    toggle();

    if (success) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Token wallet address updated successfully!',
        showConfirmButton: false,
        timer: 6000,
      });
      return setTimeout(async function () {
        if (callback && typeof callback === 'function') {
          return callback();
        }
        history.push('/account/ico');
      }, 6000);
    }
    Swal.fire({
      position: 'center',
      icon: 'error',
      title: message || 'Failed to process request, please try again!',
      showConfirmButton: false,
      timer: 6000,
    });
  }

  async function handleBuyICOToken() {
    delete postData.authenticate;
    const response: any = await TransactionService.create(postData);
    const { success, message } = response;

    setComponentLoading(false);
    toggle();

    if (success) {
      const { transaction_id } = response.data;
      Swal.fire({
        position: 'center',
        icon: 'success',
        title:
          'Request processed successfully! Please see transaction details on the next screen!',
        showConfirmButton: false,
        timer: 8000,
      });
      return setTimeout(async function () {
        return (window.location.href = `/account/ico?txid=${transaction_id}`);
      }, 8000);
    }

    Swal.fire({
      position: 'center',
      icon: 'error',
      title: message || 'Failed to process request, please try again!',
      showConfirmButton: false,
      timer: 6000,
    });
  }

  async function handleUpdateCryptoAddress() {
    delete postData.authenticate;
    const response: any = await UserService.updateCryptoAccount(
      postId,
      postData,
    );
    const { success, message } = response;

    setComponentLoading(false);
    toggle();

    if (success) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Crypto address updated successfully!',
        showConfirmButton: false,
        timer: 6000,
      });
      return setTimeout(async function () {
        if (callback && typeof callback === 'function') {
          return callback('Crypto');
        }
        history.push('/account/settings');
      }, 6000);
    }
    Swal.fire({
      position: 'center',
      icon: 'error',
      title: message || 'Failed to process request, please try again!',
      showConfirmButton: false,
      timer: 6000,
    });
  }

  async function handleWithdraw() {
    delete postData.authenticate;
    const response: any = await TransactionService.create(postData);
    const { success, message } = response;

    setComponentLoading(false);
    toggle();

    if (success) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Request processed successfully!',
        showConfirmButton: false,
        timer: 6000,
      });
      return setTimeout(async function () {
        history.push('/account/transactions');
      }, 6000);
    }
    Swal.fire({
      position: 'center',
      icon: 'error',
      title: message || 'Failed to process request, please try again!',
      showConfirmButton: false,
      timer: 6000,
    });
  }

  async function handleEnable2FA() {
    delete postData.authenticate;
    const response: any = await AuthService.enable2FA();
    const { success, message, data } = response;

    setComponentLoading(false);
    toggle();

    if (success) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Please complete 2FA setup on the next screen!',
        showConfirmButton: false,
        timer: 6000,
      });
      return setTimeout(async function () {
        callback(data);
      }, 6000);
    }
    Swal.fire({
      position: 'center',
      icon: 'error',
      title: message || 'Failed to process request, please try again!',
      showConfirmButton: false,
      timer: 6000,
    });
  }

  async function handleEnable2FAConfirm() {
    setComponentLoading(false);
    toggle();
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Request processed successfully!',
      showConfirmButton: false,
      timer: 6000,
    });
    return setTimeout(async function () {
      callback();
    }, 6000);
  }

  async function onInputComplete(code: string) {
    setComponentLoading(true);

    const response: any = await AuthService.verifyOtp({
      twofactor_setup: postData.twofactor_setup || false,
      transaction: transaction,
      twofactor: isTwoFactor,
      code,
    });
    const { success, message } = response;

    if (success) {
      switch (transaction) {
        case 'bank-account.add':
          return handleAddBankAccount();

        case 'buddy-account.add':
          return handleAddBuddyAccount();

        case 'bank-account.update':
          return handleUpdateBankAccount();

        case 'crypto-account.add':
          return handleAddCryptoAddress();

        case 'crypto-account.update':
          return handleUpdateCryptoAddress();

        case 'mobile-number.verify':
          return handleMobileNumberVerify();

        case 'mobile-number.update':
          return handleMobileNumberUpdate();

        case 'transaction.withdraw':
          return handleWithdraw();

        case '2fa.activate':
          return handleEnable2FA();

        case '2fa.activate.confirm':
          return handleEnable2FAConfirm();

        case 'token-wallet-address.add':
          return handleAddTokenAddress();

        case 'token-wallet-address.update':
          return handleUpdateTokenAddress();

        case 'ico-token-confirm.buy':
          return handleBuyICOToken();

        default:
          setComponentLoading(false);
          toggle();
          return Swal.fire({
            position: 'center',
            icon: 'error',
            title: message || 'Failed to process request, please try again!',
            showConfirmButton: false,
            timer: 6000,
          });
      }
    }

    setComponentLoading(false);

    if (attempts < 3) {
      setError(message || 'Failed to process request, please try again!');
      return setAttempts(attempts + 1);
    }

    toggle();

    return Swal.fire({
      position: 'center',
      icon: 'error',
      title: message || 'Failed to process request, please try again!',
      showConfirmButton: false,
      timer: 6000,
    });
  }

  function onInputChange(data: any) {
    setNumOfInputedFields(data.length);
  }

  const handleResendCode = async (e: any) => {
    e.preventDefault();

    if (!allowResend) return false;

    setAllowResend(false);
    setComponentLoading(true);
    const response: any = await AuthService.sendOTP({ transaction });
    const { success, message } = response;

    if (success) {
      const { mobile } = profile;
      if (mobile) {
        const musk = _mask(mobile, 4, 3);
        setCaption(
          'Please enter the {{LENGTH}} digit code sent to +{{MOBILE}}. If not received within a minute please try resend option below.'
            .replace('{{LENGTH}}', numOfFields)
            .replace('{{MOBILE}}', musk),
        );
        doResendCountdown();
        return setComponentLoading(false);
      }
    }
    setError(message || 'Failed to process request, please try again!');
  };

  return (
    <div>
      <Modal isOpen={show} toggle={toggle} size="md" backdrop="static">
        {componentLoading && <Loader type="card" />}
        <ModalHeader toggle={toggle}>OTP Verification</ModalHeader>
        <ModalBody className="pt-0">
          {action.title && (
            <p className="mt-3">
              <strong>You are about to {action.title}</strong>
            </p>
          )}
          <div className="mt-3">{caption}</div>
          {error && (
            <div className="alert alert-dismissable alert-danger px-4 py-2">
              <small>{error}</small>
            </div>
          )}
          <div className="mb-3">
            <ReactCodeInput
              type="number"
              className="otp-code-wrapper"
              onComplete={onInputComplete}
              onChange={onInputChange}
            />
          </div>
          {isTwoFactor ? (
            <p className="mb-2">
              {transaction !== 'ico-token-confirm.buy' && (
                <>
                  <i className="la la-info-circle me-2" />
                  <strong>Need Help?</strong>&nbsp;&nbsp;
                  <a
                    href="https://support.authy.com/hc/en-us/articles/115001945848-Installing-Authy-apps"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Learn about how to set up an Authy App on your device.
                  </a>
                </>
              )}
            </p>
          ) : (
            <div className="row">
              <div className="col-sm-10">
                <div className="new-account ms-4">
                  <p>
                    Did not receive SMS?&nbsp;
                    <a
                      href="/login"
                      className={`btn-resend-otp ${
                        allowResend ? '' : 'disabled'
                      }`}
                      onClick={handleResendCode}
                    >
                      {allowResend ? (
                        <span>Resend</span>
                      ) : (
                        <span>Resend in {countdown} seconds</span>
                      )}
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="text-right me-4">
                  <p className="mb-1">
                    {numOfInputedFields}/{numOfFields}
                  </p>
                </div>
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default OTPVerifyModal;
