import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { CalculationsService } from '../../services/calculations.service';
import { UserService } from '../../services/user.service';
import { _mask } from '../../../utils/fn';
import Loader from '../common/loader';
import moment from 'moment';

const BuddyWithdrawalModal = props => {
  const {
    show,
    setShow,
    amount,
    profile,
    setPostData,
    currencyCode,
    setTransaction,
    setShowOtpVerify,
    setWithdrawalType,
  } = props;
  const [agree, setAgree] = useState<boolean>(false);
  const [amountInCBI, setAmountInCBI] = useState<any>(0.0);
  const [selectedAccount, setSelectedAccount] = useState<any>({});
  const [componentLoading, setComponentLoading] = useState<boolean>(true);
  const [showAccountNumber, setShowAccountNumber] = useState<boolean>(false);
  const [withdrawalLocked, setWithdrawalLocked] = useState<boolean>(false);
  const [noBuddyAccount, setNoBuddyAccount] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<any>([]);

  async function fetchData() {
    setWithdrawalType('Buddy');
    setTransaction('transaction.withdraw');
    const accounts = await UserService.buddyAccounts();
    const calculatedAmount = await CalculationsService.withdraw(
      amount,
      currencyCode,
      'buddy',
    );
    if (accounts.results && accounts.results.length > 0) {
      setSelectedAccount(accounts.results[0]);
      setAccounts(accounts.results);
    } else {
      setNoBuddyAccount(true);
    }
    setAmountInCBI(calculatedAmount.INPUT_CBI || 0.0);

    // check if withdrawal lock is still active
    if (profile.withdrawal_lock_expiry) {
      setWithdrawalLocked(
        moment().isBefore(moment(profile.withdrawal_lock_expiry)),
      );
    }
    setComponentLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  const toggle = () => setShow(!show);

  const toggleAccountNumber = () => setShowAccountNumber(!showAccountNumber);

  const handleAccountChange = (e: any) => {
    const [filtered] = accounts.filter(
      (account: any) => account.id === e.target.value,
    );
    if (filtered) {
      setSelectedAccount(filtered);
      setShowAccountNumber(false);
    }
  };

  const handleProcessWithdrawal = async () => {
    toggle();
    setPostData({
      account_id: selectedAccount.id,
      tx_type: 'debit',
      subtype: 'withdrawal',
      currency_code: currencyCode,
      type: 'buddy',
      amount,
    });
    setShowOtpVerify(true);
  };

  // account lock for withdrawals
  if (withdrawalLocked) {
    return (
      <Modal isOpen={show} toggle={toggle} size="md" backdrop="static">
        <ModalBody className="text-center">
          <img
            src="/images/icons/danger.png"
            className="icon x2 mb-4 mt-5 opacity-6"
            alt="Warning"
          />
          <p className="mx-2 mb-4" style={{ color: '#808080' }}>
            For security purposes, <strong>no withdrawals</strong>&nbsp; allowed
            for <strong>24 hours</strong> after changes to any bank account
            details or crypto address details.
            <br />
            You will only be able to withdraw from&nbsp;
            <strong>
              {moment(profile.withdrawal_lock_expiry).format('DD-MMM-YYYY')}
            </strong>
            &nbsp;at&nbsp;
            <strong>
              {moment(profile.withdrawal_lock_expiry).format('HH:mm')}
            </strong>
          </p>
          <div className="mt-3 mb-2 text-center">
            <a
              href=" "
              className="btn btn-success w-100 bg-gradient-dark"
              onClick={(e: any) => {
                e.preventDefault();
                return toggle();
              }}
            >
              Close
            </a>
          </div>
        </ModalBody>
      </Modal>
    );
  }

  return (
    <div>
      <Modal isOpen={show} toggle={toggle} size="md" backdrop="static">
        {componentLoading && <Loader type="card" />}
        {noBuddyAccount ? (
          <ModalBody className="text-center">
            <img
              src="/images/no-history.png"
              className="icon x2 mb-4 mt-5 opacity-5"
              alt="Warning"
            />
            <p className="mx-2 mb-4" style={{ color: '#808080' }}>
              You have no linked account for buddy transfer. Click the button
              below or navigate to settings screen to link your buddy account.
            </p>
            <Link
              to="/account/settings?section=linked-accounts"
              className="btn btn-primary w-100 bg-gradient-primary"
            >
              Go to settings
            </Link>
            <div className="mt-3 mb-1 text-center">
              <a
                href=" "
                className="text-muted"
                style={{ color: '#808080' }}
                onClick={(e: any) => {
                  e.preventDefault();
                  return toggle();
                }}
              >
                Cancel
              </a>
            </div>
          </ModalBody>
        ) : (
          <>
            <ModalHeader toggle={toggle}>
              {agree
                ? 'Specify how you would want to sell'
                : 'Before you sell, please note:'}
            </ModalHeader>
            <ModalBody className="pt-0">
              {agree ? (
                <>
                  <div className="buyer-seller">
                    <small className="mt-3 mb-3 d-block">
                      Please verify information below, after confirming you can
                      submit your sell request.
                    </small>
                    <div className="mb-3">
                      <label className="me-sm-2">Choose buddy account</label>
                      {selectedAccount && selectedAccount.id && (
                        <select
                          className="form-control"
                          defaultValue={selectedAccount.id}
                          onChange={handleAccountChange}
                        >
                          {accounts.map((account: any) => (
                            <option key={account.id} value={account.id}>
                              {account.label} -{' '}
                              {_mask(account.buddy_identifier, 3, 3)}
                            </option>
                          ))}
                        </select>
                      )}
                    </div>
                    {selectedAccount && selectedAccount.buddy_identifier && (
                      <div className="table-responsive">
                        <table className="table mb-0">
                          <tbody>
                            <tr>
                              <td className="deposit-details">
                                <div className="row">
                                  <small>ACCOUNT LABEL</small>
                                  <label>{selectedAccount.label}</label>
                                </div>
                                <div className="row">
                                  <small>BUDDY IDENTIFIER</small>
                                  <label>
                                    {showAccountNumber
                                      ? selectedAccount.buddy_identifier
                                      : _mask(
                                          selectedAccount.buddy_identifier,
                                          3,
                                          3,
                                        )}
                                    &nbsp;&nbsp;
                                    <span
                                      role="button"
                                      className="pointer-on-hover"
                                      onClick={toggleAccountNumber}
                                    >
                                      <i
                                        className={`fa ${
                                          showAccountNumber
                                            ? 'fa-eye-slash'
                                            : 'fa-eye'
                                        }`}
                                      />
                                    </span>
                                  </label>
                                </div>
                                <div className="row">
                                  <small>AMOUNT TO BE DEDUCTED</small>
                                  <label>{amountInCBI} CBI</label>
                                </div>
                                {/* <div className="row">
                                                                    <small>
                                                                        ESTIMATED AMOUNT YOU WILL RECEIVE
                                                                    </small>
                                                                    <label>{receiptAmountInCBI} Smile$</label>
                                                                </div> */}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                  <ul className="unordered-list mb-3">
                    <li>
                      <small>
                        By continuing with this transaction you agree to&nbsp;
                        <a
                          href="/terms"
                          className="text-primary"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          CBI-X Terms and Conditions
                        </a>
                      </small>
                    </li>
                  </ul>
                  <div className="d-block text-center mb-2 mt-2">
                    <button
                      type="button"
                      className="btn btn-primary bg-gradient-dark px-5"
                      onClick={handleProcessWithdrawal}
                    >
                      CONFIRM &amp; SUBMIT
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <ul className="unordered-list mb-3 mt-3">
                    <li>
                      <small>
                        By continuing with this transaction you agree to&nbsp;
                        <a
                          href="/terms"
                          className="text-primary"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          CBI-X Terms and Conditions
                        </a>
                      </small>
                    </li>
                  </ul>
                  <div className="mb-3 mb-0">
                    <label className="toggle">
                      <input
                        type="checkbox"
                        className="toggle-checkbox"
                        onChange={(e: any) => setAgree(e.target.checked)}
                      />
                      <div className="toggle-switch" />
                      <span className="toggle-label">
                        I understand and accept
                      </span>
                    </label>
                  </div>
                </>
              )}
            </ModalBody>
          </>
        )}
      </Modal>
    </div>
  );
};

export default BuddyWithdrawalModal;
