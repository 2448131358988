import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Session } from './services/session.service';
import Loader from './components/common/loader';

function PrivateRoute({ component: Component, ...rest }) {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [isAuth, setIsAuth] = useState<boolean>(false);

  async function fetchData() {
    const isAuth = await Session.isValid();
    setIsAuth(isAuth);
    setLoaded(true);
  }

  useEffect(() => {
    fetchData();
  }, []);

  if (!loaded) return <Loader />;

  return (
    <Route
      {...rest}
      render={props =>
        isAuth ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={`/login?rurl=${window.location.pathname.substring(1)}`}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
