import React from 'react';

const FormError = (props: any) => {
  const { name, errors, messages } = props;
  if (
    errors[name] &&
    errors[name]['type'] === 'required' &&
    messages &&
    messages['required']
  ) {
    return (
      <small className="text-xs text-danger error">{messages['required']}</small>
    );
  }
  if (
    errors[name] &&
    errors[name]['type'] === 'pattern' &&
    messages &&
    messages['invalid']
  ) {
    return <small className="text-xs text-danger error">{messages['invalid']}</small>;
  }
  if (
    errors[name] &&
    errors[name]['type'] === 'minLength' &&
    messages &&
    messages['minLength']
  ) {
    return (
      <small className="text-xs text-danger error">{messages['minLength']}</small>
    );
  }
  if (
    errors[name] &&
    errors[name]['type'] === 'maxLength' &&
    messages &&
    messages['maxLength']
  ) {
    return (
      <small className="text-xs text-danger error">{messages['maxLength']}</small>
    );
  }
  if (
    errors[name] &&
    errors[name]['type'] === 'validate' &&
    messages &&
    messages['validate']
  ) {
    return (
      <small className="text-xs text-danger error">{messages['validate']}</small>
    );
  }
  if (
    errors[name] &&
    errors[name]['type'] === 'validate' &&
    messages &&
    messages['invalid']
  ) {
    return <small className="text-xs text-danger error">{messages['invalid']}</small>;
  }
  if (
    errors[name] &&
    errors[name]['type'] === 'manual' &&
    errors &&
    errors[name]['message']
  ) {
    return (
      <small className="text-xs text-danger error">{errors[name]['message']}</small>
    );
  }
  if (
    errors[name] &&
    errors[name]['type'] === 'max' &&
    messages &&
    messages['max']
  ) {
    return <small className="text-xs text-danger error">{messages['max']}</small>;
  }
  if (
    errors[name] &&
    errors[name]['type'] === 'min' &&
    messages &&
    messages['min']
  ) {
    return <small className="text-xs text-danger error">{messages['min']}</small>;
  }
  if (
    errors[name] &&
    errors[name]['type'] === 'custom'
  ) {
    if (
      errors[name]['message'] &&
      errors[name]['message'].length > 0
    ) {
      return <small className="text-xs text-danger error">{errors[name]['message']}</small>;
    }
    if (
      messages &&
      messages['custom']
    ) {
      return <small className="text-xs text-danger error">{messages['custom']}</small>;
    }
  }
  return <React.Fragment />;
};

export default FormError;
