import axios from 'axios';
import config from '../config';

const headers = {
  'Content-Type': 'application/json',
};

export class CurrencyService {
  /**
   * Base url
   */
  static baseurl = config.services.generic;

  static async get(code: string) {
    const options: any = {
      mode: 'no-cors',
      method: 'GET',
      url: `${this.baseurl}currencies/${code}`,
      crossdomain: true,
      headers,
    };
    return axios(options)
      .then(json => json.data)
      .then(res => res.data)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  static async list() {
    const options: any = {
      mode: 'no-cors',
      method: 'GET',
      url: `${this.baseurl}currencies`,
      crossdomain: true,
      headers,
    };
    return axios(options)
      .then(json => json.data)
      .then(res => {
        const { data } = res;
        const { results } = data;
        return results || [];
      })
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }
}
