import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { GenericService } from '../../services/generic.service';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import InputError from '../../components/form/error';
import validationMessages from '../../../static/validation-messages.json';

const errorMessages = validationMessages.crypto_account;

const UpdateCryptoAddressModal = props => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const {
    data,
    show,
    setShow,
    setPostId,
    pageLoading,
    setPostData,
    setPageLoading,
    setShowOtpVerify,
    setTransaction,
  } = props;
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(true);
  const [currencies, setCurrencies] = useState<any>([]);
  const [agree, setAgree] = useState<boolean>(false);
  const [currency, setCurrency] = useState<any>();

  async function fetchData() {
    if (!pageLoading) setPageLoading(true);
    const currencies = await GenericService.get('currencies?type=crypto');
    if (currencies.results) {
      setCurrency(data.currency_code);
      setCurrencies(currencies.results);
    }
    setPostId(data.id);
    setTransaction('crypto-account.update');
    setPageLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  const toggle = () => setShow(!show);

  async function onSubmit(data) {
    data.code = data.currency_code;

    toggle();
    setPostData(data);
    setShowOtpVerify(true);
  }

  return (
    <div>
      <Modal isOpen={show} toggle={toggle} size="md" backdrop="static">
        {showDisclaimer ? (
          <ModalBody className="text-center">
            <img
              src="/images/icons/warning.png"
              className="icon my-3"
              alt="Warning"
            />
            <p className="mx-3">
              For security purposes, <strong>no withdrawals</strong>&nbsp; will
              be allowed for <strong>24 hours</strong> after changes to any bank
              account details or crypto address details.
            </p>
            <p>
              By proceeding you agree to&nbsp;
              <a
                href="/terms"
                className="text-primary"
                target="_blank"
                rel="noopener noreferrer"
              >
                CBI-X Terms and Conditions
              </a>
            </p>
            <button
              type="button"
              className="btn btn-success w-100 bg-gradient-dark px-4"
              onClick={() => setShowDisclaimer(false)}
            >
              Got it, proceed
            </button>
            <div className="mt-3 mb-1 text-center">
              <a
                href=" "
                className="text-muted"
                onClick={(e: any) => {
                  e.preventDefault();
                  return toggle();
                }}
              >
                Cancel
              </a>
            </div>
          </ModalBody>
        ) : (
          <>
            <ModalHeader toggle={toggle}>Update Crypto Address</ModalHeader>
            <ModalBody className="pt-0">
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="signin_validate"
              >
                <div className="mb-3 mt-3">
                  <label className="me-sm-2 mb-2">Choose crypto currency</label>
                  {pageLoading ? (
                    <div>Loading...</div>
                  ) : (
                    <>
                      <select
                        className={`form-control ${
                          errors.currency_code ? 'is-invalid' : ''
                        }`}
                        defaultValue={data.currency_code}
                        {...register('currency_code', {
                          required: true,
                        })}
                        onChange={(e: any) => setCurrency(e.target.value)}
                      >
                        {currencies.map((currency: any) => (
                          <option key={currency.code} value={currency.code}>
                            {currency.code} - {currency.description} (
                            {currency.symbol})
                          </option>
                        ))}
                      </select>
                      <InputError
                        name="currency_code"
                        errors={errors}
                        messages={errorMessages.currency_code}
                      />
                    </>
                  )}
                </div>
                <div className="mb-3">
                  <label className="me-sm-2 mb-2">
                    {currency === 'CBI' ? (
                      <span>Enter your CBI username</span>
                    ) : (
                      <span>Give your account a name for display purposes</span>
                    )}
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.name ? 'is-invalid' : ''
                    }`}
                    placeholder={`E.g. ${currency} ${
                      currency === 'CBI' ? 'username' : 'address'
                    }`}
                    defaultValue={data.name}
                    {...register('name', {
                      required: true,
                      validate: value =>
                        validator.isAlphanumeric(value, 'en-US', {
                          ignore: ' ',
                        }),
                    })}
                  />
                  <InputError
                    name="name"
                    errors={errors}
                    messages={errorMessages.name}
                  />
                  {currency !== 'CBI' && (
                    <span
                      className="d-block mt-1"
                      style={{ lineHeight: '17px' }}
                    >
                      <small className="hint-text" style={{ lineHeight: 1 }}>
                        NB: Address name/label is a customized name you can give
                        to each off-ramping address for your own reference.
                      </small>
                    </span>
                  )}
                </div>
                <div className="mb-4">
                  <label className="me-sm-2 mb-2">
                    Enter your{' '}
                    {currency === 'CBI' ? 'user code' : 'crypto address'}
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.address ? 'is-invalid' : ''
                    }`}
                    placeholder={`Copy and paste ${
                      currency === 'CBI' ? 'user code' : 'address'
                    } here`}
                    defaultValue={data.address}
                    {...register('address', {
                      required: true,
                    })}
                  />
                  <InputError
                    name="address"
                    errors={errors}
                    messages={errorMessages.address}
                  />
                </div>
                <div className="mb-3 mb-0">
                  <label className="toggle">
                    <input
                      type="checkbox"
                      className="toggle-checkbox"
                      onChange={(e: any) => setAgree(e.target.checked)}
                    />
                    <div className="toggle-switch" />
                    <span className="toggle-label">
                      By submitting you agree to&nbsp;
                      <a
                        href="/terms"
                        className="text-primary"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        CBI-X Terms and Conditions
                      </a>
                    </span>
                  </label>
                </div>
                <div className="text-center mb-3">
                  <button
                    type="submit"
                    className="btn btn-success bg-gradient-dark w-100"
                    disabled={!agree}
                  >
                    Save Changes
                  </button>
                </div>
              </form>
            </ModalBody>
          </>
        )}
      </Modal>
    </div>
  );
};

export default UpdateCryptoAddressModal;
