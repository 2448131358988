module.exports = {
  stage: 'production',
  reCaptachakey: '6LcWG_wfAAAAAEG0Tlk5Ok0W1V5o1hmFNWZ8snr-',
  whitepaperUrl:
    'https://cbi-x.ams3.digitaloceanspaces.com/manuals/CBI%20DAO%20Whitepaper.pdf',
  cbixUrl: 'https://qa.cbi-x.io/',
  cbiGlobalUrl: 'https://www.cbiglobal.io/',
  auth: {
    cookie: {
      name: '___cbiXchangeWebAppUserSession',
      expires: 1, // days
    },
  },
  services: {
    auth: 'https://qa.cbi-x.io/v1/auth/',
    fileManagement: 'https://qa.cbi-x.io/v1/storage/',
    generic: 'https://qa.cbi-x.io/v1/generic/',
    transaction: 'https://qa.cbi-x.io/v1/transaction/',
    user: 'https://qa.cbi-x.io/v1/user/',
    admin: 'https://qa.cbi-x.io/v1/admin/',
  },
};
