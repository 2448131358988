import { Session } from './session.service';
import { osName, osVersion, browserName, isMobile } from 'react-device-detect';
import queryString from 'query-string';
import axios from 'axios';
import config from '../config';

const token = Session.getToken();
const headers = {
  Authorization: `Bearer ${token}`,
  'Content-Type': 'application/json',
};

export class UserService {
  /**
   * Base url
   */
  static baseurl = config.services.user;

  static async profile() {
    const options: any = {
      mode: 'no-cors',
      method: 'GET',
      url: `${this.baseurl}profile`,
      crossdomain: true,
      headers,
    };
    return axios(options)
      .then(json => json.data)
      .then(res => res.data)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  static async wallet() {
    const options: any = {
      mode: 'no-cors',
      method: 'GET',
      url: `${this.baseurl}wallet`,
      crossdomain: true,
      headers,
    };
    return axios(options)
      .then(json => json.data)
      .then(res => res.data)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  static async notifications() {
    const options: any = {
      mode: 'no-cors',
      method: 'GET',
      url: `${this.baseurl}notifications`,
      crossdomain: true,
      headers,
    };
    return axios(options)
      .then(json => json.data)
      .then(res => res.data)
      .catch(err => {
        if (err.response) return err.response.data;
        return null;
      });
  }

  static async kyc() {
    const options: any = {
      mode: 'no-cors',
      method: 'GET',
      url: `${this.baseurl}profile`,
      crossdomain: true,
      headers,
    };
    return axios(options)
      .then(json => json.data)
      .then(res => {
        const { data } = res;
        return data.kyc_level || (data && data.kyc_level === 0)
          ? { level: data.kyc_level, verified: data.verified }
          : null;
      })
      .catch(err => {
        if (err.response) return err.response.data;
        return null;
      });
  }

  static async kycApplications() {
    const options: any = {
      mode: 'no-cors',
      method: 'GET',
      url: `${this.baseurl}kyc/applications`,
      crossdomain: true,
      headers,
    };
    return axios(options)
      .then(json => json.data)
      .then(res => res.data)
      .catch(err => {
        if (err.response) return err.response.data;
        return null;
      });
  }

  static async account(code: string) {
    const options: any = {
      mode: 'no-cors',
      method: 'GET',
      url: `${this.baseurl}account/${code}`,
      crossdomain: true,
      headers,
    };
    return axios(options)
      .then(json => json.data)
      .then(res => res.data)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  static async createMobileNumber(data: any) {
    const options: any = {
      mode: 'no-cors',
      method: 'POST',
      url: `${this.baseurl}mobile_numbers`,
      crossdomain: true,
      headers,
      data,
    };
    return axios(options)
      .then(json => json.data)
      .then(res => res.data)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  static async verifyMobileNumber(data: any) {
    const options: any = {
      mode: 'no-cors',
      method: 'POST',
      url: `${this.baseurl}mobile_numbers/verify`,
      crossdomain: true,
      headers,
      data,
    };
    return axios(options)
      .then(json => json.data)
      .then(res => res)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  static async updateMobileNumber(id: string, data: any) {
    const options: any = {
      mode: 'no-cors',
      method: 'PUT',
      url: `${this.baseurl}mobile_numbers/${id}`,
      crossdomain: true,
      headers,
      data,
    };
    return axios(options)
      .then(json => json.data)
      .then(res => res)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  static async mobileNumbers() {
    const options: any = {
      mode: 'no-cors',
      method: 'GET',
      url: `${this.baseurl}mobile_numbers`,
      crossdomain: true,
      headers,
    };
    return axios(options)
      .then(json => json.data)
      .then(res => res.data)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  static async createEmailAddress(data: any) {
    const options: any = {
      mode: 'no-cors',
      method: 'POST',
      url: `${this.baseurl}email_addresses`,
      crossdomain: true,
      headers,
      data,
    };
    return axios(options)
      .then(json => json.data)
      .then(res => res)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  static async updateEmailAddress(id: string, data: any) {
    const options: any = {
      mode: 'no-cors',
      method: 'PUT',
      url: `${this.baseurl}email_addresses/${id}`,
      crossdomain: true,
      headers,
      data,
    };
    return axios(options)
      .then(json => json.data)
      .then(res => res)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  static async emailAddresses() {
    const options: any = {
      mode: 'no-cors',
      method: 'GET',
      url: `${this.baseurl}email_addresses`,
      crossdomain: true,
      headers,
    };
    return axios(options)
      .then(json => json.data)
      .then(res => res.data)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  static async createBankAccount(data: any) {
    const options: any = {
      mode: 'no-cors',
      method: 'POST',
      url: `${this.baseurl}bank_accounts`,
      crossdomain: true,
      headers,
      data,
    };
    return axios(options)
      .then(json => json.data)
      .then(res => res)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  static async createBuddyAccount(data: any) {
    const options: any = {
      mode: 'no-cors',
      method: 'POST',
      url: `${this.baseurl}buddy`,
      crossdomain: true,
      headers,
      data,
    };
    return axios(options)
      .then(json => json.data)
      .then(res => res)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  static async validateCryptoAccount(data: any) {
    const options: any = {
      mode: 'no-cors',
      method: 'POST',
      url: `${this.baseurl}crypto_accounts/validate`,
      crossdomain: true,
      headers,
      data,
    };
    return axios(options)
      .then(json => json.data)
      .then(res => res)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  static async createCryptoAccount(data: any) {
    const options: any = {
      mode: 'no-cors',
      method: 'POST',
      url: `${this.baseurl}crypto_accounts`,
      crossdomain: true,
      headers,
      data,
    };
    return axios(options)
      .then(json => json.data)
      .then(res => res)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  static async updateBankAccount(id: string, data: any) {
    const options: any = {
      mode: 'no-cors',
      method: 'PUT',
      url: `${this.baseurl}bank_accounts/${id}`,
      crossdomain: true,
      headers,
      data,
    };
    return axios(options)
      .then(json => json.data)
      .then(res => res)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  static async updateBuddyAccount(id: string, data: any) {
    const options: any = {
      mode: 'no-cors',
      method: 'PUT',
      url: `${this.baseurl}buddy/${id}`,
      crossdomain: true,
      headers,
      data,
    };
    return axios(options)
      .then(json => json.data)
      .then(res => res)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  static async updateCryptoAccount(id: string, data: any) {
    const options: any = {
      mode: 'no-cors',
      method: 'PUT',
      url: `${this.baseurl}crypto_accounts/${id}`,
      crossdomain: true,
      headers,
      data,
    };
    return axios(options)
      .then(json => json.data)
      .then(res => res)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  static async deleteBankAccount(id: string) {
    const options: any = {
      mode: 'no-cors',
      method: 'DELETE',
      url: `${this.baseurl}bank_accounts/${id}`,
      crossdomain: true,
      headers,
    };
    return axios(options)
      .then(json => json.data)
      .then(res => res)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  static async deleteBuddyAccount(id: string) {
    const options: any = {
      mode: 'no-cors',
      method: 'DELETE',
      url: `${this.baseurl}buddy/${id}`,
      crossdomain: true,
      headers,
    };
    return axios(options)
      .then(json => json.data)
      .then(res => res)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  static async deleteCryptoAccount(id: string) {
    const options: any = {
      mode: 'no-cors',
      method: 'DELETE',
      url: `${this.baseurl}crypto_accounts/${id}`,
      crossdomain: true,
      headers,
    };
    return axios(options)
      .then(json => json.data)
      .then(res => res)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  static async bankAccounts() {
    const options: any = {
      mode: 'no-cors',
      method: 'GET',
      url: `${this.baseurl}bank_accounts`,
      crossdomain: true,
      headers,
    };
    return axios(options)
      .then(json => json.data)
      .then(res => res.data)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  static async buddyAccounts() {
    const options: any = {
      mode: 'no-cors',
      method: 'GET',
      url: `${this.baseurl}buddy`,
      crossdomain: true,
      headers,
    };
    return axios(options)
      .then(json => json.data)
      .then(res => res.data)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  static async cryptoAccounts(params: any = null) {
    const query = params ? `?${queryString.stringify(params)}` : '';
    const options: any = {
      mode: 'no-cors',
      method: 'GET',
      url: `${this.baseurl}crypto_accounts${query}`,
      crossdomain: true,
      headers,
    };
    return axios(options)
      .then(json => json.data)
      .then(res => res.data)
      .catch(err => {
        if (err.response) return err.response.data;
        return err;
      });
  }

  static async captureKYCApplication(data: any) {
    data.device = {
      osName,
      osVersion,
      browserName,
      isMobile,
    };
    const options: any = {
      data,
      mode: 'no-cors',
      method: 'POST',
      url: `${this.baseurl}kyc/applications`,
      crossdomain: true,
      headers,
    };
    return axios(options)
      .then(json => json.data)
      .then(res => res)
      .catch(err => {
        if (err.response) return err.response.data;
        return null;
      });
  }
}
