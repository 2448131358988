import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { CalculationsService } from '../../services/calculations.service';
import { CurrencyService } from '../../services/currency.service';
import { UserService } from '../../services/user.service';
import { _mask } from '../../../utils/fn';
import Loader from '../common/loader';
import moment from 'moment';

const BankWithdrawalModal = props => {
  const {
    show,
    setShow,
    amount,
    profile,
    setPostData,
    currencyCode,
    setTransaction,
    setShowOtpVerify,
    setWithdrawalType,
    withdrawalType,
  } = props;
  const [agree, setAgree] = useState<boolean>(false);
  const [currency, setCurrency] = useState<any>(null);
  const [amountInCBI, setAmountInCBI] = useState<any>(0.0);
  const [receiptAmountInCBI, setReceiptAmountInCBI] = useState<any>(0.0);
  const [receiptAmount, setReceiptAmount] = useState<any>(0.0);
  const [selectedAccount, setSelectedAccount] = useState<any>({});
  const [componentLoading, setComponentLoading] = useState<boolean>(true);
  const [showAccountNumber, setShowAccountNumber] = useState<boolean>(false);
  const [withdrawalLocked, setWithdrawalLocked] = useState<boolean>(false);
  const [noBankAccount, setNoBankAccount] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<any>([]);

  async function fetchData() {
    setWithdrawalType('DafriBank');
    setTransaction('transaction.withdraw');
    const accounts = await UserService.bankAccounts();
    const currency = await CurrencyService.get(currencyCode);
    const calculatedAmount = await CalculationsService.withdraw(
      amount,
      currencyCode,
    );
    if (accounts.results && accounts.results.length > 0) {
      setSelectedAccount(accounts.results[0]);
      setAccounts(accounts.results);
    } else {
      setNoBankAccount(true);
    }
    setReceiptAmountInCBI(calculatedAmount.CBI || 0.0);
    setReceiptAmount(calculatedAmount[currencyCode] || 0.0);
    setAmountInCBI(calculatedAmount.INPUT_CBI || 0.0);
    setCurrency(currency);

    // check if withdrawal lock is still active
    if (profile.withdrawal_lock_expiry) {
      setWithdrawalLocked(
        moment().isBefore(moment(profile.withdrawal_lock_expiry)),
      );
    }
    setComponentLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  const toggle = () => setShow(!show);

  const toggleAccountNumber = () => setShowAccountNumber(!showAccountNumber);

  const handleBankChange = (e: any) => {
    const [filtered] = accounts.filter(
      (account: any) => account.id === e.target.value,
    );
    if (filtered) {
      setSelectedAccount(filtered);
      setShowAccountNumber(false);
    }
  };

  const handleProcessWithdrawal = async () => {
    toggle();
    setPostData({
      account_id: selectedAccount.id,
      tx_type: 'debit',
      subtype: 'withdrawal',
      currency_code: currencyCode,
      type: 'bank',
      amount,
    });
    setShowOtpVerify(true);
  };

  // account lock for withdrawals
  if (withdrawalLocked) {
    return (
      <Modal isOpen={show} toggle={toggle} size="md" backdrop="static">
        <ModalBody className="text-center">
          <img
            src="/images/icons/danger.png"
            className="icon x2 mb-4 mt-5 opacity-6"
            alt="Warning"
          />
          <p className="mx-2 mb-4" style={{ color: '#808080' }}>
            For security purposes, <strong>no withdrawals</strong>&nbsp; allowed
            for <strong>24 hours</strong> after changes to any bank account
            details or crypto address details.
            <br />
            You will only be able to withdraw from&nbsp;
            <strong>
              {moment(profile.withdrawal_lock_expiry).format('DD-MMM-YYYY')}
            </strong>
            &nbsp;at&nbsp;
            <strong>
              {moment(profile.withdrawal_lock_expiry).format('HH:mm')}
            </strong>
          </p>
          <div className="mt-3 mb-2 text-center">
            <a
              href=" "
              className="btn btn-success w-100 bg-gradient-dark"
              onClick={(e: any) => {
                e.preventDefault();
                return toggle();
              }}
            >
              Close
            </a>
          </div>
        </ModalBody>
      </Modal>
    );
  }

  return (
    <div>
      <Modal isOpen={show} toggle={toggle} size="md" backdrop="static">
        {componentLoading && <Loader type="card" />}
        {noBankAccount ? (
          <ModalBody className="text-center">
            <img
              src="/images/no-history.png"
              className="icon x2 mb-4 mt-5 opacity-5"
              alt="Warning"
            />
            <p className="mx-2 mb-4" style={{ color: '#808080' }}>
              You have no linked account for bank sell. Click the button below
              or navigate to settings screen to add bank account.
            </p>
            <Link
              to="/account/settings?section=linked-accounts"
              className="btn btn-primary w-100 bg-gradient-primary"
            >
              Go to settings
            </Link>
            <div className="mt-3 mb-1 text-center">
              <a
                href=" "
                className="text-muted"
                style={{ color: '#808080' }}
                onClick={(e: any) => {
                  e.preventDefault();
                  return toggle();
                }}
              >
                Cancel
              </a>
            </div>
          </ModalBody>
        ) : (
          <>
            <ModalHeader toggle={toggle}>
              {agree
                ? 'Specify how you would want to sell'
                : 'Before you sell, please note:'}
            </ModalHeader>
            <ModalBody className="pt-0">
              {agree ? (
                <>
                  <div className="buyer-seller">
                    <small className="mt-3 mb-3 d-block">
                      Please verify information below, after confirming you can
                      submit your sell request.
                    </small>
                    <div className="mb-3">
                      <label className="me-sm-2">
                        How would you like to receive your CBI?
                      </label>
                      <select
                        className="form-control"
                        defaultValue={withdrawalType}
                        onChange={(e: any) => setWithdrawalType(e.target.value)}
                      >
                        <option value="DafriBank">Dafribank</option>
                        {/* <option value="Bank">Bank Account</option> */}
                      </select>
                    </div>
                    {withdrawalType === 'Bank' && (
                      <div className="mb-3">
                        <label className="me-sm-2">Choose bank account</label>
                        {selectedAccount && selectedAccount.id && (
                          <select
                            className="form-control"
                            defaultValue={selectedAccount.id}
                            onChange={handleBankChange}
                          >
                            {accounts.map((account: any) => (
                              <option key={account.id} value={account.id}>
                                {account.bank_name} -{' '}
                                {_mask(account.number, 3, 3)}
                              </option>
                            ))}
                          </select>
                        )}
                      </div>
                    )}
                    {selectedAccount && selectedAccount.number && (
                      <div className="table-responsive">
                        <table className="table mb-0">
                          <tbody>
                            <tr>
                              <td className="deposit-details">
                                {withdrawalType === 'DafriBank' ? (
                                  <>
                                    <div className="row">
                                      <small>BANK NAME</small>
                                      <label>Dafribank</label>
                                    </div>
                                    <div className="row">
                                      <small>ACCOUNT IDENTIFIER</small>
                                      <label>{profile.email}</label>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="row">
                                      <small>ACCOUNT NAME</small>
                                      <label>{selectedAccount.name}</label>
                                    </div>
                                    <div className="row">
                                      <small>BANK NAME</small>
                                      <label>{selectedAccount.bank_name}</label>
                                    </div>
                                    <div className="row">
                                      <small>ACCOUNT NUMBER</small>
                                      <label>
                                        {showAccountNumber
                                          ? selectedAccount.number
                                          : _mask(selectedAccount.number, 3, 3)}
                                        &nbsp;&nbsp;
                                        <span
                                          role="button"
                                          className="pointer-on-hover"
                                          onClick={toggleAccountNumber}
                                        >
                                          <i
                                            className={`fa ${
                                              showAccountNumber
                                                ? 'fa-eye-slash'
                                                : 'fa-eye'
                                            }`}
                                          />
                                        </span>
                                      </label>
                                    </div>
                                  </>
                                )}
                                <div className="row">
                                  <small>AMOUNT TO BE DEDUCTED</small>
                                  <label>{amountInCBI} CBI</label>
                                </div>
                                <div className="row">
                                  <small>
                                    ESTIMATED AMOUNT YOU WILL RECEIVE
                                  </small>
                                  <label>{receiptAmountInCBI} CBI</label>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                  <ul className="unordered-list mb-3">
                    <li>
                      <small>
                        Note: 1 Namibian Dollar (NAD) = 1 South African Rand
                        (ZAR)
                      </small>
                    </li>
                    <li>
                      <small>
                        By continuing with this transaction you agree to&nbsp;
                        <a
                          href="/terms"
                          className="text-primary"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          CBI-X Terms and Conditions
                        </a>
                      </small>
                    </li>
                  </ul>
                  <div className="d-block text-center mb-2 mt-2">
                    <button
                      type="button"
                      className="btn btn-primary bg-gradient-dark px-5"
                      onClick={handleProcessWithdrawal}
                    >
                      CONFIRM &amp; SUBMIT
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <ul className="unordered-list mb-3 mt-3">
                    <li>
                      <small>
                        This may take up to 5 business days to reflect.
                      </small>
                    </li>
                    <li>
                      <small>
                        Clearance into your Dafribank might take up to 30 hours.
                      </small>
                    </li>
                    <li>
                      <small>Withdrawals are only processed on weekdays.</small>
                    </li>
                    <li>
                      <small>
                        Sell will only be made to verified accounts.
                      </small>
                    </li>
                    <li>
                      <small>
                        By selling you agree to&nbsp;
                        <a
                          href="/terms"
                          className="text-primary"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          CBI-X Terms and Conditions
                        </a>
                      </small>
                    </li>
                  </ul>
                  <div className="mb-3 mb-0">
                    <label className="toggle">
                      <input
                        type="checkbox"
                        className="toggle-checkbox"
                        onChange={(e: any) => setAgree(e.target.checked)}
                      />
                      <div className="toggle-switch" />
                      <span className="toggle-label">
                        I understand and accept
                      </span>
                    </label>
                  </div>
                </>
              )}
            </ModalBody>
          </>
        )}
      </Modal>
    </div>
  );
};

export default BankWithdrawalModal;
