import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FileManagementService } from 'app/services/file.management.service';
import { GenericService } from 'app/services/generic.service';
import { GeoLocationService } from 'app/services/geolocation.service';
import { UserService } from 'app/services/user.service';
import { StepOne, StepTwo, StepThree } from '../kyc/level-two';
import { _mask } from '../../../utils/fn';
import StepWizard from 'react-step-wizard';
import Loader from '../common/loader';
import Swal from 'sweetalert2';

const KYCLevelTwoModal = (props: any) => {
  const { show, setShow, getApplications } = props;
  const [componentLoading, setComponentLoading] = useState<boolean>(true);
  const [stepWizard, setStepWizard] = useState<any>(null);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [validationError, setValidationError] = useState<any>();
  const [countries, setCountries] = useState<any>([]);
  const [document, setDocument] = useState<any>();
  const [geoinfo, setGeoinfo] = useState<any>();
  const [profile, setProfile] = useState<any>();

  async function getGeoInfo() {
    const geoinfo = await GeoLocationService.get();
    setGeoinfo(geoinfo);
  }

  async function fetchData() {
    const countries: any = await GenericService.get('countries');
    const profile: any = await UserService.profile();
    setCountries(countries.results || []);
    setProfile(profile);
    setComponentLoading(false);
  }

  useEffect(() => {
    fetchData();
    getGeoInfo();
  }, []);

  const toggle = () => setShow(!show);

  const handleStepChange = (e: any) => {
    setActiveStep(e.activeStep - 1);
  };

  const handleComplete = async (data: any) => {
    const post: any = {
      geoinfo,
      level: 2,
      data: {
        document_type: 'bank-confirmation',
        address: data,
      },
    };

    /// validate if file(s) uploaded
    if (!document) {
      return setValidationError(
        <p className="mb-0">
          Please choose proof of residence document file to upload.
        </p>,
      );
    }

    setComponentLoading(true);

    // handle upload bank confirmation file
    const formData: any = new FormData();
    formData.append('filename', document);
    formData.append('uid', profile.id);
    formData.append('folder', 'kyc');

    post.documents = [];
    const uploadResponse = await FileManagementService.upload(formData);

    // check if docoument is uploaded successfully
    if (uploadResponse && uploadResponse.filename) {
      post.documents.push(uploadResponse.filename);

      // handle capture kyc record
      const response: any = await UserService.captureKYCApplication(post);
      setComponentLoading(false);

      if (response.success) {
        toggle();
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Request successfully processed!',
          showConfirmButton: false,
          timer: 4000,
        });
        return getApplications();
      }
    }

    return setValidationError(
      <p className="mb-0">Failed to process request, please try again!</p>,
    );
  };

  const assignStepWizard = (instance: any) => {
    setStepWizard(instance);
  };

  return (
    <div>
      <Modal isOpen={show} toggle={toggle} size="md" backdrop="static">
        {componentLoading && <Loader type="card" />}
        <ModalHeader toggle={toggle}>KYC Level 2 Application</ModalHeader>
        <ModalBody>
          <div className="kyc-wrapper">
            <StepWizard
              instance={assignStepWizard}
              onStepChange={handleStepChange}
            >
              <StepOne />
              <StepTwo
                document={document}
                error={validationError}
                setDocument={setDocument}
                setError={setValidationError}
              />
              <StepThree
                countries={countries}
                error={validationError}
                handleComplete={handleComplete}
              />
            </StepWizard>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default KYCLevelTwoModal;
